<template>
  <transition name="fade">
    <div v-if="isOpen" @click.self="closeModal" class="info-modal__wrap">
      <div v-if="isOpen" class="info-modal">
        <img @click="closeModal" class="info-modal__close" :src="require('@/assets/image/modal-cross.svg')"
             alt="modal-cross.svg">

        <div class="pay" v-if="modalType === 'pay'">
          <h3 class="info-modal__title">Оплата</h3>
          <div class="info-modal__text">
            <form @submit.prevent="sendPay" v-if="!isLoadingSpinner">
              <div v-if="order">
                <div v-if="order.is_paid">
                  <div class="pay__order-price">
                    <h3 class="pay__title">Проживание</h3>
                    <p v-if="+order.is_paid === 2">Стоимость проживания оплачена полностью</p>
                    <p v-else class="">
                      Минимальная сумма оплаты<br>для заселения
                      <span class="pay__price">{{ totalPrice }} ₽</span>
                    </p>
                  </div>
                  <div class="pay__other-serves">
                    <h3 class="pay__title">Дополнительные услуги</h3>
                    <div class="pay__other-serves__elems">
                      <div v-for="(item, index) in otherOrderItems" key="index">
                        <label>
                          <input type="checkbox" @click="toggleCheckboxItems(item.id)">
                          {{ item.title }} - {{ item.price }}&nbsp;₽
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="booking__text-politic__wrap">
                    <input required id="politic" type="checkbox" @click="policy = !policy">
                    <label for="politic">
                      Я согласен с
                      <router-link class="link_orange" to="/offer">публичной офертой</router-link>
                      и
                      <router-link class="link_orange" to="/policy">политикой обработки персональных данных
                      </router-link>
                    </label>
                  </div>

                  <div class="pay__total">
                    <span class="pay__price">Итого: {{ (totalPrice + otherTotalPrice) ?? 0 }} ₽</span>
                    <div class="date-list pay__date-list" v-if="allOrderItems.length">
                      <img :src="require('@/assets/image/icon/warning.svg')" alt="warning">
                      <div class="date-list__modal pay__date-list__modal">
                        <table class="date-list__table">
                          <tr v-for="(item, index) in allOrderItems" :key="index">
                            <td class="date-list__table__date">{{ item.title }}:</td>
                            <td class="date-list__table__price">{{ item.price }}₽</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                  <button class="button_orange pay__button-send"
                          :disabled="isLoading || !(totalPrice + otherTotalPrice)">
                    Перейти к оплате
                  </button>
                </div>
                <div v-else>
                  <p>Пожалуйста, внесите предоплату</p>
                  <p>
                    Если вы не попали на форму оплаты автоматически, воспользуйтесь письмом, которое было выслано вам на
                    почту
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div v-else-if="modalType === 'remove'">
          <h3 class="info-modal__title">Отменить/изменить бронирование</h3>
          <div class="info-modal__text">

            <form @submit.prevent="sendOrderRemove" v-if="!isLoadingSpinner">
              <div v-if="order">
                <div class="remove__inputs">
                  <div class="remove__inputs__radio">
                    <label>
                      <input type="radio" name="remove-type" @click="removeType = 1" checked>
                      Изменить
                    </label>
                    <label>
                      <input type="radio" name="remove-type" @click="removeType = 2">
                      Отменить
                    </label>
                  </div>
                  <div class="remove__input-wrap">
                    <textarea rows="5" class="remove__input" required :placeholder="removeType === 1
                    ? 'Что хотите изменить?'
                    : 'Почему хотите отменить?'" v-model="comment"></textarea>
                    <sup class="remove__input__error" v-for="error in errors.comment">{{ error }}</sup>
                  </div>
                </div>

                <button class="button_orange remove__button-send">
                  Отправить
                </button>
              </div>
            </form>

          </div>
        </div>

        <div v-if="isLoadingSpinner" class="loader-wrap">
          <div class="loader">Loading...</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {httpApi} from "@/helper/httpHelper";

export default {
  name: 'ModalAccount',
  props: {},
  data() {
    return {
      isLoadingSpinner: false,
      modalType: 'pay', // pay|remove
      order: {},
      policy: false,
      isOpen: false,
      isLock: false,
      isLoading: false,
      orderItems: [],
      otherOrderItems: [],
      otherOrderItemsSelected: [],
      removeType: 1,
      comment: '',
      errors: {},
    };
  },
  methods: {
    openModal(type, order, lock = false, loading = false) {
      this.modalType = type;
      this.order = order;
      this.isOpen = true;
      this.isLock = lock;
      this.isLoadingSpinner = loading;
      document.body.classList.add('no-scroll');
      if (type === 'pay') {
        this.getOrderPayItems();
      }
    },
    toggleCheckboxItems(id) {
      const existIndex = this.otherOrderItemsSelected.findIndex((elem) => +elem.id === +id);
      if (existIndex !== -1) {
        this.otherOrderItemsSelected.splice(existIndex, 1);
      } else {
        const item = this.otherOrderItems.filter((elem) => +elem.id === +id);
        this.otherOrderItemsSelected.push(item[0]);
      }
    },
    closeModal() {
      if (this.isLock) return;
      this.isOpen = false;
      document.body.classList.remove('no-scroll')
    },
    getPrettyDate(dateStr) {
      const date = new Date(dateStr);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().substr(-2);
      return `${day}.${month}.${year}`
    },
    getOrderPayItems() {
      this.orderItems = [];
      this.otherOrderItems = [];
      this.otherOrderItemsSelected = [];
      this.isLoadingSpinner = true;
      this.errors = {};

      httpApi.get(`pay/order-items?orderId=${this.order.id}`, {
        headers: {
          'Authorization': `Bearer ${this.userToken}`,
        }
      })
        .then(res => {
          this.orderItems.push(...res.data.orderItems);
          this.otherOrderItems.push(...res.data.entertainmentItems);
        })
        .finally(() => {
          this.isLoadingSpinner = false;
        })
    },
    sendPay() {
      this.isLoadingSpinner = true;
      this.errors = {};

      httpApi.post(
        'orders/create-postpaid',
        {
          orderId: this.order.id,
          totalPrice: +this.totalPrice + +this.otherTotalPrice,
          itemsSelectedId: this.otherOrderItemsSelected.map((elem) => {
            return elem.id;
          }),
        },
        {
          headers: {'Authorization': `Bearer ${localStorage.getItem('userToken')}`}
        })
        .then(res => {
          window.location.replace(res.data.paymentUrl);
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          console.error(err);
        })
        .finally(() => {
          this.isLoadingSpinner = false;
        })
    },
    sendOrderRemove() {
      this.isLoadingSpinner = true;
      this.errors = {};

      httpApi.post(
        'orders/remove',
        {
          orderId: this.order.id,
          removeType: this.removeType,
          comment: this.comment,
        },
        {
          headers: {'Authorization': `Bearer ${localStorage.getItem('userToken')}`}
        })
        .then(res => {
          this.closeModal();
          this.comment = '';
          this.removeType = 1;
          this.$root.$refs.info.openInfo('Заявка отправлена');
        })
        .catch(err => {
          this.errors = err.response.data.errors;
          console.error(err);
        })
        .finally(() => {
          this.isLoadingSpinner = false;
        })
    },
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    userToken() {
      return this.$store.state.userToken;
    },
    allOrderItems() {
      return [...this.orderItems, ...this.otherOrderItemsSelected];
    },
    totalPrice() {
      return this.orderItems.reduce((total, item) => {
        return total + item.price;
      }, 0);
    },
    otherTotalPrice() {
      return this.otherOrderItemsSelected.reduce((total, item) => {
        return total + item.price;
      }, 0);
    },
  },
}
</script>

<style scoped lang="scss">

.pay {
  &__title {
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #181C16;
    margin-bottom: 0.3em;
  }

  &__order-price {
    margin: 2em auto;
  }

  &__other-serves {
    margin: 2em auto;

    &__elems {
    }
  }

  &__total {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }

  &__price {
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #181C16;
  }

  &__button-send {
    padding: 10px 20px;
  }

  &__date-list {
    //width: 10em;
  }

  &__date-list__modal {
    right: -66px;
    width: 300px;

    &::before {
      left: 212px;
    }

    &::after {
      left: 198px;
    }
  }
}

.remove {
  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    margin-bottom: 20px;

    &__radio {
      display: flex;
      gap: 30px;
    }
  }

  &__input {
    resize: none;
    background: #FFFFFF;
    border: 2px solid #8ECEF9;
    border-radius: 2px;
    padding: 10px 16px;
    width: 100%;
    transition: color 0.3s;
    outline: none;

    &-wrap {
      width: 100%;
    }

    &:hover, &:focus {
      color: #8ECEF9;
    }

    &:hover::placeholder, &:focus::placeholder {
      color: #8ECEF9;
    }

    &::placeholder {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      transition: color 0.3s;
    }

    &__error {
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      color: #FA5956;
      margin: 4px 0;
    }
  }

  &__button-send {
    padding: 10px 20px;
    width: 100%;
  }
}

.info-modal {
  overflow: hidden;
  border-radius: 8px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  margin: 10vh;
  min-width: 400px;

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  &__wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(36, 36, 36, 0.43);
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: scroll;
  }

  &__title {
    margin: 50px 10% 20px;
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #181C16;
  }

  &__text {
    margin: 0 10% 50px;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #181C16;
  }
}

@media screen and (max-width: 769px) {
  .info-modal {
    margin: 5vh;

    &__title {
      margin: 40px 10% 10px;
    }

    &__text {
      margin: 0 10% 40px;
    }
  }
}

@media screen and (max-width: 481px) {
  .pay {
    &__title {
      font-size: 18px;
      line-height: 21px;
    }
  }
  .info-modal {
    min-width: 300px;
    margin: 2vh;

    &__title {
      margin: 20px 20% 10px;
      font-size: 18px;
      line-height: 21px;
    }

    &__text {
      margin: 0 10% 20px;
      font-size: 14px;
      line-height: 150%;
    }
  }
}

// loader
.loader,
.loader:before,
.loader:after {
  background: #8ECEF9;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #FF7640;
  text-indent: -9999em;
  margin: 0 auto;
  position: absolute;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &-wrap {
    position: relative;
    height: 4em;
    margin-bottom: 10px;
  }
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
</style>
