<template>
  <div class="auth-modal auth-modal_pass">
    <img @click="$emit('closeModal')" class="auth-modal__close" src="@/assets/image/modal-cross.svg" alt="modal-cross.svg">
    <div class="auth-modal__pass-reset">
      <h3>Восстановление пароля</h3>
      <p>Мы вышлем инструкции вам на почту</p>
    </div>

    <form @submit.prevent="sendForm">
      <div class="auth-modal__inputs auth-modal__inputs_reset-pass">
        <span class="auth-modal__inputs__info">Введите адрес, к которому привязан аккаунт</span>

        <input required class="input input_forget-pass" type="email" v-model="email" placeholder="Почта">
        <p class="input__error" v-for="error in errors.email">{{ error }}</p>

        <p class="input__error" v-for="error in errors.total">{{ error }}</p>
      </div>

      <button :disabled="isLoading" class="button_orange auth-modal__send" type="submit">
        <SvgPreloader v-if="isLoading" type="orange"/>
        <span v-else>Отправить</span>
      </button>
    </form>

    <p v-if="isShowBack" @click="setLoginType" class="auth-modal__back-to-login">
      ❮ Вернуться назад
    </p>
  </div>
</template>

<script>
import {httpApi} from "@/helper/httpHelper";
import authTypes from "@/constants/authTypes";
import SvgPreloader from "@/components/blocks/SvgPreloader.vue";

export default {
  name: "ResetPass",
  components: {SvgPreloader},
  data() {
    return {
      isLoading: false,
      email: '',
      errors: {},
    }
  },
  mounted() {
    if (this.$store.state.isAuth) {
      this.email = this.$store.state.userEmail;
    }
  },
  computed: {
    isShowBack() {
      return this.$route.name !== 'Account';
    },
  },
  methods: {
    setLoginType() {
      this.$emit('changeType', authTypes.login);
    },
    sendForm() {
      this.isLoading = true;
      this.errors = {};

      httpApi({
        method: 'POST',
        url: 'reset-password',
        data: {email: this.email, password: this.password}
      })
        .then(() => {
          this.$emit('closeModal');

          this.$root.$refs.info.openInfo(
            'Сброс пароля',
            'Вам на почту отправлено письмо с инструкцией сброса пароля'
          );
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
}
</script>

<style scoped lang="scss">
.auth-modal {
  &_pass {
    height: 441px;
  }

  &__pass-reset {
    & h3 {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #181C16;
      margin: 0;
    }

    & p {
      max-width: 450px;;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #181C16;
      margin: 7px 0 19px;
    }
  }

  &__inputs {
    &_reset-pass {
      padding: 20px 12px 30px;
    }

    &__info {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #181C16;
      margin-bottom: 2px;
    }
  }

  &__back-to-login {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin-top: 40px;
    color: #181C16;
    padding: 6px 8px;
    transition: font-weight 0.3s, background-color 0.3s;
    cursor: pointer;

    & span {
      margin-right: 5px;
    }

    &:hover span, &:focus span {
      font-weight: normal;
    }

    &:hover, &:focus {
      font-weight: bold;
    }

    &:active {
      font-weight: normal;
      background: #EFEFEF;
    }
  }
}

.input_forget-pass {
  width: 302px;
}

@media screen and (max-width: 481px) {
  .auth-modal {
    &_pass {
      height: 401px;
    }

    &__inputs {
      padding: 16px;

      &__info {
        width: 236px;
      }
    }

    &__pass-reset {
      & h3 {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }

  .input_forget-pass {
    width: 236px;
  }
}
</style>
