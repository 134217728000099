<template>
  <transition name="fade">
    <div v-if="isOpen" @click.self="closeInfo" class="info-modal__wrap">
      <div v-if="isOpen" class="info-modal">
        <img @click="closeInfo" class="info-modal__close" :src="require('@/assets/image/modal-cross.svg')"
             alt="modal-cross.svg">
        <h1 class="info-modal__title">{{ title }}</h1>
        <p class="info-modal__text">{{ text }}</p>
        <div v-if="isLoading" class="loader-wrap">
          <div class="loader">Loading...</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalInfo',
  props: {},
  data() {
    return {
      title: '',
      text: '',
      isOpen: false,
      isLock: false,
      isLoading: false,
    };
  },
  methods: {
    openInfo(title, text, lock = false, loading = false) {
      this.setText(title, text)
      this.isOpen = true;
      this.isLock = lock;
      this.isLoading = loading;
      document.body.classList.add('no-scroll');
    },
    closeInfo() {
      if (this.isLock) return;
      this.isOpen = false;
      this.setText()
      document.body.classList.remove('no-scroll');
    },
    closeForce() {
      this.isOpen = false;
      this.setText()
      document.body.classList.remove('no-scroll');
    },
    setText(title = '', text = '') {
      this.title = title;
      this.text = text;
    }
  }
}
</script>

<style scoped lang="scss">
.info-modal {
  overflow: hidden;
  border-radius: 8px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  margin: 10vh;
  min-width: 400px;

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  &__wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(36, 36, 36, 0.43);
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: scroll;
  }

  &__title {
    margin: 50px 10% 20px;
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #181C16;
  }

  &__text {
    margin: 0 10% 50px;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #181C16;
  }
}

@media screen and (max-width: 769px) {
  .info-modal {
    margin: 5vh;

    &__title {
      margin: 40px 10% 10px;
    }

    &__text {
      margin: 0 10% 40px;
    }
  }
}

@media screen and (max-width: 481px) {
  .info-modal {
    min-width: 300px;
    margin: 2vh;

    &__title {
      margin: 20px 20% 10px;
      font-size: 18px;
      line-height: 21px;
    }

    &__text {
      margin: 0 10% 20px;
      font-size: 14px;
      line-height: 150%;
    }
  }
}

.loader,
.loader:before,
.loader:after {
  background: #8ECEF9;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}

.loader {
  color: #FF7640;
  text-indent: -9999em;
  margin: 0 auto;
  position: absolute;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &-wrap {
    position: relative;
    height: 4em;
    margin-bottom: 10px;
  }
}

.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}

.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.loader:after {
  left: 1.5em;
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
</style>
