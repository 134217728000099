<template>
    <div id="map" class="map"></div>
</template>

<script>
export default {
  name: "YaMap",
  data() {
    return {}
  },
  mounted() {
    this.loadYaMap();

    setTimeout(() => {
      ymaps.ready(this.init);
    }, 200);
  },
  methods: {
    loadYaMap() {
      if (!document.getElementById('yamap-script')) {
        let tag = document.createElement("script");
        tag.setAttribute("src", '');
        tag.setAttribute("id", 'yamap-script');
        document.head.appendChild(tag);
      }
    },

    init() {
      const myMap = new ymaps.Map("map", {
        center: [55.76, 37.64],
        zoom: 13,
      });

      const multiRoute = new ymaps.multiRouter.MultiRoute({
        // Точки маршрута. Точки могут быть заданы как координатами, так и адресом.
        referencePoints: [
          'МКАД, 63-й километр, внутренняя сторона',
          [55.986055, 35.386720],
        ]
      }, {
        // Автоматически устанавливать границы карты так,
        // чтобы маршрут был виден целиком.
        boundsAutoApply: true
      });

      // Добавление маршрута на карту.
      myMap.geoObjects.add(multiRoute);
    },
  },
  components: {}
}
</script>

<style scoped lang="scss">
.map {
  width: 100%;
  height: 100%;
}
</style>
