<template>
  <Skillet v-if="isLoading"></Skillet>
  <div v-else>
    <div class="booking__title__wrap">
      <h3 class="booking__title">Дом "{{ house.title }}"</h3>
      <p class="booking__description">{{ house.description }}</p>
    </div>
    <div class="booking__wrap">
      <div class="booking__left">
        <div class="booking__left__img-total">
          <img class="booking__img" :src="houseFirstImgUrl" alt="house-img">
          <div class="booking__total">
            <div class="booking__total__buttons">
              <div class="booking__calendar">
                <button class="booking__calendar__button button_blue" @click="openCalendar()">Изменить даты</button>
                <Calendar inputId="search" :isOpen="isOpenCalendar" mode="range" :disable="house.orders"></Calendar>
              </div>
              <GuestsSelector :color="'blue'" type="booking"></GuestsSelector>
            </div>
            <div class="booking__total__info">
              <div class="booking__data-list__wrap">
                <div class="date-list date-list_booking" v-if="dateList">
                  <img :src="require('@/assets/image/icon/warning.svg')" alt="warning">
                  <div class="date-list__modal">
                    <table class="date-list__table">
                      <tr v-for="(price, date) in dateList.list">
                        <td class="date-list__table__date">{{ date }}:</td>
                        <td class="date-list__table__price">{{ price }}₽</td>
                      </tr>
                    </table>
                  </div>
                </div>
                <p class="booking__total__info__date">{{ dateFrom ?? 'дд.мм.гг' }} - {{ dateTo ?? 'дд.мм.гг' }}</p>
              </div>
              <p class="booking__total__info__price">
                {{ totalPrice ?? 0 }} ₽ / {{ getDeclination(diffDates, 'сутки', 'суток', 'суток') }}
              </p>
            </div>
          </div>
        </div>
        <p class="booking__left__info">
          Заезд осуществляется с 15:00 и до 22:00, выезд — до 12:00.<br>
          Ранний заезд и поздний выезд оговаривается отдельно при наличии свободного домика.
        </p>
        <p class="booking__left__info__link">
          <router-link to="/faq" class="link_orange">Условия бронирования на одни сутки</router-link>
          <br>
        </p>
        <router-link to="/faq" class="link_orange">Правила отмены бронирования</router-link>
      </div>

      <div class="booking__right">
        <form action="" @submit.prevent="initBeforeSend">
          <input type="text" class="booking__input" placeholder="Имя*" v-model="name" @change="nameErrorHandler(name)">
          <sup class="booking__input__error" v-for="error in errors.name">{{ error }}</sup>

          <input type="text" class="booking__input" placeholder="Телефон*" @input="updatePhone" :value="phone"
                 maxlength="18" @change="phoneErrorHandler(phone)">
          <sup class="booking__input__error" v-for="error in errors.phone">{{ error }}</sup>

          <input type="email" class="booking__input" placeholder="Почта*" v-model="email" @change="emailErrorHandler(email)">
          <sup class="booking__input__error" v-for="error in errors.email">{{ error }}</sup>

          <textarea rows="5" class="booking__input" :placeholder="`Примечание\nНапример, хочу банный чан`"
                    v-model="comment"
                    @change="commentErrorHandler(comment)"/>
          <sup class="booking__input__error" v-for="error in errors.comment">{{ error }}</sup>

          <PromoCode :houseId="id" @setDiscount="setDiscount" @clearDiscount="clearDiscount" />

          <sup class="booking__input__error" v-for="error in errors.diffDates">{{ error }}</sup>

          <div class="booking__text-politic__wrap">
            <input required id="politic" type="checkbox" @click="policy = !policy" :checked="policy">
            <label for="politic">Я согласен с
              <router-link class="link_orange" to="/offer">публичной офертой</router-link>
              и
              <router-link class="link_orange" to="/policy">политикой обработки персональных данных</router-link>
            </label>
          </div>
          <label v-show="totalPrice" class="booking__prepayment">
            <input type="radio" name="price" @click="payType = 2" checked>
            Предоплата 50% {{ Math.floor(totalPrice / 2) }} ₽
          </label>
          <label v-show="totalPrice" class="booking__prepayment">
            <input type="radio" name="price" @click="payType = 1">
            Оплатить полностью {{ totalPrice }} ₽
          </label>

          <sup class="booking__input__error booking__input__error_total" v-for="error in errors.total">{{ error }}</sup>

          <button class="button_orange booking__button-send" :disabled="!isReady ? true : isLoadingOrder">
            {{ errorMess !== '' ? errorMess : 'Перейти к оплате' }}
          </button>
        </form>
      </div>

    </div>
  </div>
</template>

<script>
import Calendar from "@/components/blocks/Сalendar";
import GuestsSelector from "@/components/blocks/GuestsSelector";
import Skillet from "@/components/blocks/Skillet";
import {httpApi} from "@/helper/httpHelper";
import PromoCode from "@/components/blocks/Booking/PromoCode.vue";
import {setMaskOnPhone, setCursorPosition, checkPhone} from "@/services/phoneService";
import {checkComment, createDateList} from "@/services/orderService";
import {diffInDays, getPrettyDate} from "@/services/dateService";
import {checkEmail, checkName} from "@/services/userService";

export default {
  name: "Booking",
  components: {PromoCode, Skillet, GuestsSelector, Calendar},
  data() {
    return {
      house: {},
      discount: {},
      specialDays: {},
      dateList: {},
      diffDates: 0,
      totalPrice: 0,
      id: Number(this.$route.params.id),
      isOpenCalendar: false,
      isLoading: false,
      isLoadingOrder: false,
      errorMess: '',
      errors: {},
      errorsText: {
        'date': 'Выберите даты',
        'fields': 'Заполните поля'
      },
      name: localStorage.userName ?? '',
      phone: localStorage.userPhone ?? '',
      email: localStorage.userEmail ?? '',
      comment: '',
      policy: false,
      payType: 2, // 2-50%; 1-100%
    }
  },
  mounted() {
    this.getDataHouse(this.id);
  },
  methods: {
    setDiscount(discount) {
      this.discount = discount;
      this.updateTotalPrice();
    },
    clearDiscount() {
      this.discount = {};
      this.updateTotalPrice();
    },
    getDeclination(val, one, two, three) {
      const valEnd = val % 10;
      let declination = one;
      if (valEnd >= 2 && valEnd <= 4) {
        declination = two;
      } else if (+val >= 5 && +val <= 20 || valEnd === 0 || val >= 22 && valEnd !== 1) {
        declination = three;
      }
      return `${val} ${declination}`;
    },
    updatePhone(e) {
      if (e.inputType === 'deleteContentBackward' || e.inputType === 'deleteContentForward') {
        this.phone = e.target.value
        return;
      }

      let originVal = true;
      const val = e.data;
      const oldVal = this.phone

      if (val !== null && /\d/.test(val)) {
        if (this.phone + val !== e.target.value) {
          this.phone = e.target.value;
          originVal = false;
        }
        this.phone = setMaskOnPhone(this.phone, val, originVal);
      } else {
        this.phone = oldVal === '' ? ' ' : '';
        this.phone = oldVal;
      }
      setCursorPosition(e.target);
    },
    initBeforeSend() {
      const data = {
        houseId: Number(this.house.id),
        totalPrice: Number(this.totalPrice),
        countGuests: Number(this.$store.state.countGuests),
        dateFrom: this.$store.state.dateFrom,
        dateTo: this.$store.state.dateTo,
        policy: Number(this.policy),
        payType: Number(this.payType),
        name: this.name.trim(),
        phone: this.phone.trim(),
        email: this.email.trim(),
        comment: this.comment.trim(),
        diffDates: this.diffDates,
      };

      if (this.discount) data.discountId = this.discount.id;

      if (this.checkData(data)) {
        this.$root.$refs.info.openInfo(
          '',
          'Ожидается перенаправление на форму оплаты',
          true,
          true
        );
        this.sendOrderData(data);
      }
    },
    checkData(data) {
      this.errors = {};

      this.nameErrorHandler(data.name);
      this.phoneErrorHandler(data.phone);
      this.emailErrorHandler(data.email);
      this.commentErrorHandler(data.comment);
      this.checkDiffDates(data.diffDates);

      return !Object.keys(this.errors).length;
    },
    nameErrorHandler(name) {
      checkName(name)
        ? delete this.errors.name
        : this.errors.name = 'Имя должно быть не более двух слов и состоять только из букв';
    },
    phoneErrorHandler(phone) {
      checkPhone(phone)
        ? delete this.errors.phone
        : this.errors.phone = 'Неверный номер телефона';
    },
    emailErrorHandler(email) {
      checkEmail(email)
        ? delete this.errors.email
        : this.errors.email = 'Некорректный email';
    },
    commentErrorHandler(comment) {
      checkComment(comment)
        ? delete this.errors.comment
        : this.errors.comment = 'Разрешенные символы: точки и запятые';
    },
    checkDiffDates(diffDates) {
      let specialDays = [];
      if (this.specialDays.length) {
        const {dateFrom, dateTo} = this.$store.state;
        specialDays = this.specialDays.filter((day) => day.date >= dateFrom && day.date < dateTo)
      }

      if (specialDays.length) {
        let maxDays = 0;
        for (const date of specialDays) {
          if (date.min_days > maxDays) maxDays = date.min_days;
        }
        if (maxDays <= diffDates) delete this.errors.diffDates;
        else this.errors.diffDates = `Бронирование от ${maxDays}-х суток`;
      } else if (diffDates > 1) {
        delete this.errors.diffDates;
      } else {
        let isCrossStart = false, isCrossEnd = false;
        let toDay = new Date();
        const {dateFrom, dateTo} = this.$store.state;
        const diffDaysFromNow = diffInDays(new Date(), new Date(dateFrom));
        const day = toDay.getDate().toString().padStart(2, '0');
        const month = (toDay.getMonth() + 1).toString().padStart(2, '0');
        const year = toDay.getFullYear();

        for (const order of this.house.orders) {
          if (dateFrom === order.to || dateFrom === `${year}-${month}-${day}`) isCrossStart = true;
          if (dateTo === order.from) isCrossEnd = true;
        }

        if (isCrossStart && isCrossEnd || diffDaysFromNow <= 7) delete this.errors.diffDates;
        else this.errors.diffDates = 'Бронирование от 2-х суток';
      }
    },
    sendOrderData(data) {
      this.isLoadingOrder = true;
      this.errors = {};

      httpApi.post('orders', data, {
        headers: {'Authorization': `Bearer ${localStorage.getItem('userToken')}`}
      })
        .then(res => {
          window.location.replace(res.data.paymentUrl);
        })
        .catch(err => {
          this.$root.$refs.info.closeForce();
          this.errors = err.response.data.errors;
        })
        .finally(() => {
          this.isLoadingOrder = false;
        })
    },
    getDataHouse(id) {
      this.isLoading = true;

      httpApi.get( `data-houses/${id}`)
        .then(res => {
          this.house = res.data.house;
          this.specialDays = res.data.specialDays;
          this.updateTotalPrice();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    openCalendar() {
      this.isOpenCalendar = false;
      setTimeout(() => {
        this.isOpenCalendar = true;
      }, 100)
    },
    updateTotalPrice() {
      this.dateList = createDateList(
        this.$store.state.dateFrom,
        this.$store.state.dateTo,
        this.countGuests,
        this.house,
        this.specialDays,
        this.discount
      );
      this.totalPrice = this.dateList.totalPrice;
      this.diffDates = Object.keys(this.dateList.list).length;
    },
    readyErrors() {
      if (!this.dateFrom || !this.dateTo) {
        this.errorMess = this.errorsText['date'];
        return 'date'
      } else if (this.name === '' || this.phone === '' || this.email === '') {
        this.errorMess = this.errorsText['fields'];
        return 'fields'
      }
      this.errorMess = '';
      return 'ready';
    },
  },
  computed: {
    isReady() {
      return this.readyErrors() === 'ready';
    },
    houseFirstImgUrl() {
      if (Object.keys(this.house).length && this.house.imgs.length) return this.house.imgs[0].url;
      return '';
    },
    dateFrom() {
      if (this.$store.state.dateFrom) return getPrettyDate(new Date(this.$store.state.dateFrom));
      return null;
    },
    dateTo() {
      if (this.$store.state.dateTo) return getPrettyDate(new Date(this.$store.state.dateTo));
      return null;
    },
    dates() {
      return {
        from: this.$store.state.dateFrom,
        to: this.$store.state.dateTo
      }
    },
    countGuests() {
      return this.$store.state.countGuests;
    }
  },
  watch: {
    dates() {
      this.isOpenCalendar = false;
      this.updateTotalPrice();
    },
    countGuests() {
      this.updateTotalPrice();
    }
  },
}
</script>

<style scoped lang="scss">
textarea {
  resize: none;
}

.booking {
  &__title {
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #181C16;
    margin-bottom: 4px;

    &__wrap {
      max-width: 944px;
      margin: 80px auto 40px;
    }
  }

  &__description {
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #181C16;
  }

  &__wrap {
    max-width: 944px;
    margin: 0 auto 220px;
    display: flex;
    gap: 112px;
  }

  &__input {
    padding: 10px 16px;
    background: #FFFFFF;
    border: 2px solid #8ECEF9;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    margin-bottom: 7px;
    transition: color 0.3s;

    &:last-child {
      margin-bottom: 16px;
    }

    &::placeholder {
      transition: color 0.3s;
    }

    &:hover, &:focus {
      outline: none;
      color: #8ECEF9;
    }

    &:hover::placeholder, &:focus::placeholder {
      color: #8ECEF9;
    }

    &:disabled {
      background: #EFEFEF;
      border-color: #D5D5D5;
      color: #929292;

      &:hover, &:focus {
        color: #929292;
      }
    }

    &__error {
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      color: #FA5956;

      &_total {
        font-size: 14px;
      }
    }
  }

  &__img {
    width: 368px;
    height: 220px;
    margin-bottom: 20px;
    border-radius: 4px;
    object-fit: cover;
  }

  &__total {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 30px;

    &__info {
      text-align: right;
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: #181C16;
    }
  }

  &__calendar {
    position: relative;

    &__button {
      padding: 8px 24px;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      width: 159px;
    }
  }

  &__left {
    &__info {
      width: 368px;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #181C16;
      margin-bottom: 40px;

      &__link {
        margin-bottom: 10px;
      }
    }
  }

  &__text-politic__wrap {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #181C16;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 27px;
  }

  &__prepayment {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    & input {
      margin-right: 8px;
    }
  }

  &__button-send {
    padding: 17px;
    width: 100%;
  }

  &__data-list__wrap {
    display: flex;
    align-items: center;
    gap: 2px;
  }
}

@media screen and (max-width: 1281px) {
  .booking {
    &__title {
      &__wrap {
        max-width: 744px;
      }
    }

    &__wrap {
      max-width: 744px;
      margin: 0 auto 111px;
      gap: 16px;
    }

    &__img {
      width: 364px;
      margin-bottom: 11px;
    }

    &__text-politic__wrap {
      & input {
        margin-bottom: 21px;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .booking {
    &__title {
      &__wrap {
        max-width: 668px;
        margin: 80px auto 20px;
      }
    }

    &__wrap {
      max-width: 668px;
    }

    &__img {
      width: 326px;
      margin-bottom: 11px;
    }

    &__left {
      &__info {
        width: inherit;
      }
    }

    &__total {
      gap: 2px;
    }

    &__button-send {
      padding: 9.5px;
    }

    &__calendar {
      &__button {
        padding: 8px 6px;
        width: 125px;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .booking {
    &__title {
      &__wrap {
        max-width: 447px;
      }
    }

    &__wrap {
      max-width: 447px;
      margin: 0 auto 40px;
      gap: 20px;
      flex-direction: column;
    }

    &__img {
      width: 215px;
      height: 130px;
      margin-bottom: 0;
    }

    &__total {
      align-items: flex-end;
      gap: 16px;
      margin-bottom: 0;
      flex-direction: column;

      &__info {
        text-align: right;
      }
    }

    &__calendar {
      &__button {
        width: 100%;
      }
    }

    &__left {
      &__info {
        margin-bottom: 8px;
      }

      &__img-total {
        display: flex;
        gap: 16px;
        margin-bottom: 20px;
      }
    }

    &__text-politic__wrap {
      margin-bottom: 15px;

      & input {
        margin-bottom: 0;
      }
    }

    &__button-send {
      margin-top: 4px;
    }
  }
}

@media screen and (max-width: 481px) {
  .booking {
    &__title {
      &__wrap {
        max-width: 288px;
        margin: 53px auto 20px;
      }
    }

    &__wrap {
      max-width: 288px;
    }

    &__img {
      width: 288px;
      height: 174px;
      margin-bottom: 8px;
    }

    &__total {
      align-items: flex-start;
      gap: 5px;

      &__buttons {
        display: flex;
      }

      &__info {
        text-align: left;
      }
    }

    &__calendar {
      width: 50%;
    }

    &__left {
      &__img-total {
        flex-direction: column;
        margin-bottom: 16px;
      }
    }

    &__text-politic__wrap {
      & input {
        margin-bottom: 21px;
      }
    }

    &__data-list__wrap {
      flex-direction: row-reverse;
      gap: 0;
    }
  }
}
</style>
