<template>
  <div class="entertainments" v-if="!isLoading">
    <h3 class="entertainments__title">Как можно провести время</h3>
    <p class="entertainments__subtitle">Воспользоваться предложениями вы можете во время проживания в домике.</p>

    <section class="entertainments-list">
      <article v-for="enter in entertainments" class="entertainments-item">
        <div class="entertainments-item__text">
          <div class="entertainments-item__text-main">
            <div class="entertainments-item__title-wrap">
              <h4 class="entertainments-item__title">{{ enter.title }}</h4>
              <p class="entertainments-item__cost-free" v-if="enter.price !== '0'">{{ enter.unit }} / {{ enter.price }}₽</p>
              <p class="entertainments-item__cost-free" v-else>Это бесплатно!</p>
            </div>
            <div class="entertainments-item__subline"></div>
            <p class="entertainments-item__description">{{ enter.description }}</p>
            <p class="entertainments-item__cost-mob-free" v-if="enter.price !== '0'">{{ enter.unit }} / {{ enter.price }}₽</p>
            <p class="entertainments-item__cost-mob-free" v-else>Это бесплатно!</p>

          </div>
          <p v-if="enter.price" class="entertainments-item__info">Узнать подробности можно у нашего менеджера</p>
        </div>
        <img v-if="enter.img.length" class="entertainments-item__img" :src="enter.img[0].url" alt="enter-img">
      </article>
    </section>
  </div>
  <Skillet v-else></Skillet>
</template>

<script>
import Skillet from "./blocks/Skillet";
import {httpApi} from "@/helper/httpHelper";

export default {
  name: "Entertainments",
  components: {Skillet},
  data() {
    return {
      isLoading: false,
      entertainments: [],
    }
  },
  mounted() {
    this.getEntertainments();
  },
  methods: {
    getEntertainments() {
      this.isLoading = true;

      httpApi.get( 'entertainments')
        .then(res => {
          this.entertainments = res.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
  },
}
</script>

<style scoped lang="scss">
.entertainments {
  max-width: 1136px;
  margin: 80px auto 50px;

  &__title {
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 48px;
    line-height: 120%;
    color: #181C16;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #181C16;
    margin-bottom: 80px;
  }

  &-list {
  }

  &-item {
    margin-bottom: 100px;
    display: flex;
    justify-content: space-between;
    gap: 16px;

    &:last-child {
      margin-bottom: 0;
    }

    &__text {
      flex: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__title {
      font-family: Tenor Sans, sans-serif;
      font-weight: normal;
      font-size: 36px;
      line-height: 120%;
      color: #181C16;
      max-width: 272px;

      &-wrap {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__cost {
      font-family: Tenor Sans, sans-serif;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #181C16;

      &-free {
        font-family: Tenor Sans, sans-serif;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        color: #FF7640;
        padding: 16px 18px;
        background: #FFF1EC;
      }
    }

    &__subline {
      width: 272px;
      border-bottom: 2px solid #181C16;
      margin: 16px 0;
    }

    &__description {
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: #181C16;
      max-width: 368px;
    }

    &__cost-mob {
      font-family: Tenor Sans, sans-serif;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: #181C16;
      margin-bottom: 16px;
      display: none;

      &-free {
        background: #FFF1EC;
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        color: #FF7640;
        display: none;
        padding: 4px;
        text-align: center;
        margin-bottom: 16px;
      }
    }

    &__info {
      background: rgba(142, 206, 249, 0.2);
      border-radius: 2px;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #181C16;
      padding: 12px 20px;
    }

    &__img {
      width: 560px;
      height: 308px;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: 1281px) {
  .entertainments {
    max-width: 896px;
    margin: 60px auto 40px;

    &-item {
      margin-bottom: 80px;

      &__title {
        font-size: 24px;
        line-height: 28px;
        max-width: 136px;

        &-wrap {
          margin-top: 0;
        }
      }

      &__cost {
        font-size: 18px;
        line-height: 21px;

        &-free {
          font-size: 14px;
          line-height: 150%;
          padding: 8px 16px;
        }
      }

      &__subline {
        width: 136px;
        margin: 12px 0;
      }

      &__description {
        max-width: 287px;
      }

      &__img {
        width: 439px;
        height: 242px;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .entertainments {
    max-width: 668px;
    margin: 30px auto 40px;

    &__title {
      font-size: 36px;
      margin-bottom: 4px;
    }

    &__subtitle {
      font-size: 18px;
      line-height: 150%;
      margin-bottom: 40px;
    }

    &-item {
      flex-direction: column;

      &__subline {
        width: 216px;
      }

      &__description {
        max-width: 326px;
        margin-bottom: 16px;
      }

      &__img {
        width: 100%;
        height: inherit;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .entertainments {
    max-width: 448px;
  }
}

@media screen and (max-width: 481px) {
  .entertainments {
    max-width: 288px;

    &__subtitle {
      font-size: 14px;
      line-height: 150%;
      margin-bottom: 60px;
    }

    &-item {
      margin-bottom: 60px;

      &__title {
        margin-bottom: 4px;
      }

      &__cost {
        display: none;

        &-free {
          display: none;
        }
      }

      &__subline {
        display: none;
      }

      &__description {
        font-size: 14px;
        line-height: 150%;
        width: 100%;
      }

      &__cost-mob {
        display: block;

        &-free {
          display: block;
        }
      }

      &__info {
        padding: 8px 20px;
      }
    }
  }

}
</style>
