<template>
  <div class="contacts">
    <h2 class="contacts__title">Как добраться из Москвы</h2>
    <div class="contacts__way">
      <div class="contacts__img__wrap contacts__img__wrap_car">
        <h3 class="contacts__info__title contacts__info__title_mob">На машине:</h3>
        <img class="contacts__img contacts__img_car" :src="require('@/assets/image/contacts/car.svg')" alt="bus.svg">
      </div>
      <div class="contacts__img__wrap contacts__img__wrap_bus">
        <h3 class="contacts__info__title contacts__info__title_mob">На автобусе:</h3>
        <img class="contacts__img contacts__img_bus" :src="require('@/assets/image/contacts/bus.svg')" alt="car.svg">
      </div>
      <div class="contacts__img__wrap contacts__img__wrap_train">
        <h3 class="contacts__info__title contacts__info__title_mob">На поезде:</h3>
        <img class="contacts__img contacts__img_train" :src="require('@/assets/image/contacts/train.svg')"
             alt="train.svg">
      </div>
      <div class="contacts__info contacts__info_car">
        <h3 class="contacts__info__title_main contacts__info__title">На машине:</h3>
        <p class="contacts__info__text">Время в пути: 1 часа 30 минут</p>
        <p class="contacts__info__text">По новорижскому шоссе</p>
        <p class="contacts__info__text">Ближайшая к нам деревня: Муриково</p>
        <p class="contacts__info__text">Координаты для навигатора: 55.986247, 35.386186</p>
      </div>
      <div class="contacts__info contacts__info_bus">
        <h3 class="contacts__info__title_main contacts__info__title">На автобусе:</h3>
        <p class="contacts__info__text">Время в пути: 2 часа 20 минут</p>
        <p class="contacts__info__text">Откуда: Автовокзал Северные ворота</p>
        <p class="contacts__info__text">или Москва метро Тушинская</p>
        <p class="contacts__info__text">Куда: Шаховская</p>
        <p class="contacts__info__text">Далее мы оплачиваем такси до нашего участка.</p>
        <p class="contacts__info__text">Обратная дорога оплачивается гостями самостоятельно.</p>
      </div>
      <div class="contacts__info contacts__info_train">
        <h3 class="contacts__info__title_main contacts__info__title">На поезде:</h3>
        <p class="contacts__info__text">Время в пути: 3 часа</p>
        <p class="contacts__info__text">Откуда: Курский вокзал</p>
        <p class="contacts__info__text">или Рижский вокзал</p>
        <p class="contacts__info__text">Куда: Шаховская</p>
        <p class="contacts__info__text">Далее мы оплачиваем такси до нашего участка.</p>
        <p class="contacts__info__text">Обратная дорога оплачивается гостями самостоятельно.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WayToUs",
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss">
.contacts {
  max-width: 1135px;
  margin: 80px auto 90px;

  &__title {
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 36px;
    line-height: 120%;
    color: #181C16;
  }

  &__way {
    margin-top: 67px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 16px;
    row-gap: 40px;
    align-items: flex-end;
  }

  &__info {
    align-self: flex-start;

    &__title {
      font-family: Tenor Sans, sans-serif;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #181C16;
      margin-bottom: 16px;

      &_mob {
        display: none;
      }
    }

    &__text {
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: #181C16;
      margin-bottom: 15px;
    }
  }
}

@media screen and (max-width: 1281px) {
  .contacts {
    max-width: 896px;
  }
}

@media screen and (max-width: 1025px) {
  .contacts {
    max-width: 668px;

    &__title {
      font-size: 24px;
      line-height: 28px;
    }

    &__way {
      margin-top: 45px;
    }

    &__img {
      &_bus {
        width: 212px;
        height: 105px;
      }

      &_train {
        width: 212px;
        height: 127px;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .contacts {
    max-width: 448px;
    margin: 60px auto 80px;

    &__way {
      margin-top: 30px;
      grid-template-columns: repeat(2, 1fr);
      align-items: center;
    }

    &__info {
      &_car {
        order: 1;
      }

      &_bus {
        order: 4;
      }

      &_train {
        order: 5;
      }

      &__title {
        font-size: 18px;
        line-height: 21px;
      }

      &__text {
        font-size: 14px;
        line-height: 150%;
        margin-bottom: 0;
      }
    }

    &__img {
      width: 216px;

      &__wrap {
        &_car {
          order: 2;
        }

        &_bus {
          order: 3;
        }

        &_train {
          order: 6;
        }
      }
    }
  }
}

@media screen and (max-width: 481px) {
  .contacts {
    max-width: 288px;
    margin: 40px auto 80px;

    &__way {
      margin-top: 20px;
      grid-template-columns: 1fr;
      gap: 10px;
    }

    &__info {
      margin-bottom: 40px;
      &_car {
        order: 2;
      }

      &_bus {
        order: 4;
      }

      &_train {
        order: 6;
      }

      &__title {
        &_main {
          display: none;
        }

        &_mob {
          display: block;
        }
      }

    }

    &__img {
      width: 136px;

      &__wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &_car {
          order: 1;
        }

        &_bus {
          order: 3;
        }

        &_train {
          order: 5;
        }
      }
    }
  }
}
</style>
