<template>
  <WayToUs></WayToUs>
  <BottomContacts></BottomContacts>
</template>

<script>
import WayToUs from "@/components/blocks/WayToUs";
import BottomContacts from "@/components/blocks/BottomContacts";
export default {
  name: "Contacts",
  components: {BottomContacts, WayToUs},
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped lang="scss">
</style>
