<template>
  <div v-if="!isLoading" class="faq">
    <h2 class="faq__title">Частые вопросы</h2>
    <div class="faq__list">
      <div class="faq__item" v-for="elem in discounts" :id="`discount_${elem.id}`">
        <div @click="toggleTab(elem.id, 'discount')" class="faq__item-title-wrap">
          <h4 class="faq__item-title">{{ elem.title }}</h4>
          <img :src="require('@/assets/image/plus.svg')" alt="plus.svg"
               :class="{'faq__item-cross': true, 'faq__item-cross_open': elem.isOpen}">
        </div>
        <div class="faq__item-text-wrap" :id="`discount_collapse_${elem.id}`">
          <div class="faq__item-text">
            <div v-html="elem.description"></div>
          </div>
        </div>
      </div>
      <div class="faq__item" v-for="elem in questions" :id="`question_${elem.id}`">
        <div @click="toggleTab(elem.id)" class="faq__item-title-wrap">
          <h4 class="faq__item-title">{{ elem.title }}</h4>
          <img :src="require('@/assets/image/plus.svg')" alt="plus.svg"
               :class="{'faq__item-cross': true, 'faq__item-cross_open': elem.isOpen}">
        </div>
        <div class="faq__item-text-wrap" :id="`question_collapse_${elem.id}`">
          <div class="faq__item-text">
            <div v-html="elem.description"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Skillet v-else></Skillet>
</template>

<script>
import Skillet from "@/components/blocks/Skillet";
import {httpApi} from "@/helper/httpHelper";

export default {
  name: "Faq",
  components: {Skillet},
  data() {
    return {
      isBusy: {},
      isLoading: true,
      questions: [],
      discounts: [],
    }
  },
  mounted() {
    this.getData();
    this.handlerHash();
  },
  updated() {
  },
  methods: {
    handlerHash() {
      if (this.$route.hash) {
        const {hash} = this.$route;
        setTimeout(() => {
          if (document.querySelector(hash)) window.scrollTo(0, document.querySelector(hash).offsetTop);
        }, 10);
      }
    },
    toggleTab(id, type = 'question') {
      if (this.isBusy[id]) return;
      const elem = this[type + 's'].find((value) =>  +id === +value.id);
      this.isBusy[id] = true;
      const mainElem = document.getElementById(`${type}_collapse_${elem.id}`);
      const currHeight = parseInt(window.getComputedStyle(mainElem).getPropertyValue('height'));
      let targetHeight = 0;

      if (!elem.isOpen) {
        targetHeight = parseInt(window.getComputedStyle(mainElem.firstChild).getPropertyValue('height'));
      }

      elem.isOpen = !elem.isOpen;
      this.animateHeight(id, mainElem, currHeight, targetHeight);
    },
    animateHeight(id, elem, from, to) {
      let curr = from;
      const duration = 50; // speed coefficient

      const step = +(Math.abs(from - to) / duration).toFixed(1);
      const interId = setInterval(() => {
        if (curr > to) {
          curr -= step;
          if (curr <= to) curr = to;
        } else {
          curr += step;
          if (curr >= to) curr = to;
        }

        elem.style.height = `${curr}px`;
        if (curr === to) {
          clearInterval(interId);
          this.isBusy[id] = false;
        }
      }, 1);
    },
    getData() {
      this.isLoading = true;

      httpApi.get( 'faq-and-discount')
        .then(res => {
          this.questions = res.data.questions;
          this.discounts = res.data.discounts;
          this.handlerHash();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
  },
}
</script>

<style scoped lang="scss">
$padding-x: 16px;
$padding-y: 20px;
.faq {
  max-width: 896px;
  margin: 60px auto 40px;

  &__title {
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 48px;
    line-height: 120%;
    color: #181C16;
    margin-bottom: 60px;
  }

  &__item {
    border-top: 2px solid #8ECEF9;

    &:last-child {
      border-bottom: 2px solid #8ECEF9;
    }

    &-title {
      font-family: Tenor Sans, sans-serif;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #181C16;

      &-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        padding: $padding-x $padding-y;
      }
    }

    &-cross {
      transition: transform .3s, background-color .3s;
      padding: 1px;
      border-radius: 100%;

      &_open {
        transform: rotate(45deg);
      }
    }

    &-title-wrap:hover &-cross {
      background: #E3F4FF;
    }

    &-text {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #181C16;
      padding: 0 $padding-y $padding-x;

      &-wrap {
        height: 0;
        overflow: hidden;
      }
    }
  }
}

@media screen and (max-width: 1281px) {
  .faq {
    max-width: 940px;
    margin: 80px auto 60px;
  }
}

@media screen and (max-width: 1025px) {
  $padding-x: 20px;
  $padding-y: 20px;
  .faq {
    max-width: 668px;
    margin: 40px auto 80px;

    &__title {
      font-size: 36px;
      margin-bottom: 40px;
    }

    &__item {
      &-title {
        font-size: 18px;
        line-height: 21px;

        &-wrap {
          padding: $padding-x $padding-y;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .faq {
    max-width: 448px;
    margin: 40px auto 40px;

    &__title {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 481px) {
  $padding-x: 20px;
  $padding-y: 16px;
  .faq {
    max-width: 288px;

    &__title {
      font-size: 24px;
      line-height: 28px;
    }

    &__item {
      &-title {
        &-wrap {
          padding: $padding-x $padding-y;
        }
      }
    }
  }
}
</style>
