<template>
  <div class="auth-modal auth-modal_log">
    <img @click="$emit('closeModal')" class="auth-modal__close" src="@/assets/image/modal-cross.svg"
         alt="modal-cross.svg">
    <h3 class="auth-modal__title">Вход</h3>

    <form @submit.prevent="sendForm">
      <div class="auth-modal__inputs auth-modal__inputs_log">
        <input required class="input" type="email" v-model="email" placeholder="Почта">
        <p class="input__error" v-for="error in errors.email">{{ error }}</p>

        <input required minlength="8" class="input" type="password" v-model="password"
               placeholder="Пароль">
        <p class="input__error" v-for="error in errors.password">{{ error }}</p>

        <div class="input__error" v-for="error in errors.total">
          <SendEmailButton
            @closeModal="$emit('closeModal')"
            v-if="error === 'Подтвердите электронную почту'"
            :email="email"
            :error="error"
          />
          <p v-else>{{ error }}</p>
        </div>
        <span @click="setResetType" class="auth-modal__inputs__forget-pass">Забыли пароль?</span>
      </div>

      <button :disabled="isLoading" class="button_blue auth-modal__send" type="submit">
        <SvgPreloader v-if="isLoading"/>
        <span v-else>Войти</span>
      </button>
    </form>

    <div class="auth-modal__reg-with" v-if="isTestMode">
      <p>Войти используя</p>
      <div class="reg-with-icons">
        <button type="button" @click.prevent="redirectToGoogle" class="clear-button">
          <img :src="require('@/assets/image/icon/google.svg')" alt="google">
        </button>
      </div>
    </div>

    <p class="auth-modal__to-login">
      Нет аккаунта?
      <span class="auth-modal__to-login__button" @click="setRegType">Зарегистрироваться</span>
    </p>
  </div>
</template>

<script>
import {httpApi} from "@/helper/httpHelper";
import {setUser} from "@/services/authService";
import authTypes from "@/constants/authTypes";
import SendEmailButton from "@/components/blocks/AuthModal/SendEmailButton.vue";
import SvgPreloader from "@/components/blocks/SvgPreloader.vue";

export default {
  name: "Login",
  components: {SvgPreloader, SendEmailButton},
  data() {
    return {
      isLoading: false,
      email: '',
      password: '',
      errors: {},
    }
  },
  computed: {
    isTestMode() {
      return Boolean(sessionStorage.getItem('isTestMode'));
    },
  },
  methods: {
    redirectToGoogle() {
      this.isLoading = true;
      this.errors = {};

      httpApi.get('auth/google/get-redirect-url')
        .then(res => {
          window.location.replace(res.data);
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setRegType() {
      this.$emit('changeType', authTypes.registration);
    },
    setResetType() {
      this.$emit('changeType', authTypes.resetPass);
    },
    sendForm() {
      this.isLoading = true;
      this.errors = {};

      httpApi({
        method: 'POST',
        url: 'login',
        data: {email: this.email, password: this.password}
      })
        .then(res => {
          this.$emit('closeModal');
          this.$store.commit('setIsAuth', true);
          const {id, name, email, phone, avatar, token} = res.data;
          setUser(id, name, email, phone, avatar, token);
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
}
</script>

<style scoped lang="scss">
.clear-button {
  background: none;
  border: none;
  cursor: pointer;
}

.auth-modal {
  &_log {
    height: 577px;
  }

  &__inputs {
    &_log {
      padding: 24px 24px 33px;
    }

    &__forget-pass {
      position: absolute;
      right: 31px;
      bottom: 8px;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #181C16;
      cursor: pointer;
    }
  }

  &__reg-with {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #181C16;
    margin-top: 15px;
  }
}

.reg-with-icons {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

@media screen and (max-width: 481px) {
  .auth-modal {
    &_log {
      height: 446px;
    }

    &__inputs_log {
      padding: 16px 16px 33px;
    }
  }
}
</style>
