<template>
  <div @touchstart="touchStart" @touchmove="touchMove" @touchend="touchEnd">
    <div class="carousel" :class="`carousel_${idElem}`">
      <div class="carousel-wrap" :class="`carousel-wrap_${idElem}`" :id="`carousel-wrap_${id}`">
        <div class="carousel__slide-wrap" v-for="(slide, index) in slides">
          <img class="carousel__slide" :id="id + '-' + index"  :src="slide.url" :key="index" alt="">
        </div>
      </div>
    </div>
    <nav class="carousel-nav" :class="`carousel-nav_${idElem}`">
      <div class="carousel-nav__links" :class="`carousel-nav__links_${idElem}`">
      <span class="carousel-nav__links__link" :class="index === currentSlide ? 'carousel-nav__links__link_curr' : ''"
            @click="setSlide(index)" v-for="(slide, index) in slides" :data-id="index"></span>
      </div>
      <div class="carousel-nav__buttons" :class="`carousel-nav__buttons_${idElem}`">
        <button class="carousel-nav__buttons__button" @click="prev" :class="`carousel-nav__buttons__button_${idElem}`">
          <img src="../../assets/image/arrows/left-orange.svg" alt="left-orange.svg">
        </button>
        <button class="carousel-nav__buttons__button" @click="next">
          <img src="../../assets/image/arrows/right-orange.svg" alt="right-orange.svg">
        </button>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  props: {
    id: String,
    slides: Array,
  },
  data() {
    return {
      isBlocked: false,
      currentSlide: 0,
      mainElem: '',
      touchX: 0,
      touchY: 0,
      intervalId: 0,
    }
  },
  mounted() {
    this.init();
    this.startInterval();
  },
  methods: {
    startInterval() {
      this.intervalId = setInterval(() => {
        this.next()
      }, 5000);
    },
    restartInterval() {
      clearInterval(this.intervalId);
      this.startInterval();
    },
    init() {
      this.mainElem = document.getElementById(`carousel-wrap_${this.id}`);
    },
    getNextIndex() {
      return this.currentSlide + 1 <= this.slides.length - 1 ? this.currentSlide + 1 : 0
    },
    getPrevIndex() {
      return this.currentSlide - 1 >= 0 ? this.currentSlide - 1 : this.slides.length - 1
    },
    animate(nextIndex) {
      this.mainElem.animate(
          [{right: `${this.currentSlide * 100}%`}, {right: `${nextIndex * 100}%`}],
          {duration: 300, fill: 'both'}
      )
    },
    next() {
      this.restartInterval();
      const nextIndex = this.getNextIndex();
      this.animate(nextIndex);
      this.currentSlide = nextIndex;
      this.$emit('changeSlide', {slide: nextIndex, id: this.id});
    },
    prev() {
      this.restartInterval();
      const prevIndex = this.getPrevIndex();
      this.animate(prevIndex);
      this.currentSlide = prevIndex;
      this.$emit('changeSlide', {slide: prevIndex, id: this.id});
    },
    setSlide(index) {
      if (+index < 0 || +index > +this.slides.length - 1) return;
      this.animate(index);
      this.currentSlide = index;
      this.$emit('changeSlide', {slide: index, id: this.id});
    },
    touchStart(e) {
      if (e.changedTouches.length === 1) {
        this.touchX = e.changedTouches[0].clientX;
        this.touchY = e.changedTouches[0].clientY;
      }
    },
    touchMove(e) {
      const {clientX, clientY} = e.changedTouches[0];
      if (e.cancelable && Math.abs(this.touchX - clientX) > Math.abs(this.touchY - clientY)) e.preventDefault();
    },
    touchEnd(e) {
      const {clientX, clientY} = e.changedTouches[0];
      if (Math.abs(this.touchX - clientX) > Math.abs(this.touchY - clientY)) {
        if (clientX > this.touchX) this.prev();
        else this.next();
      }
      this.touchX = 0;
      this.touchY = 0;
    }
  },
  computed: {
    idElem() {
      return this.id.match(/home/) ? 'home' : this.id
    },
  }
}
</script>

<style scoped lang="scss">
.carousel {
  overflow: hidden;
  margin: auto;
  width: 780px;
  height: 529px;

  &-wrap {
    position: relative;
    display: flex;
    width: 780px;
    height: 529px;

    &_home {
      width: 464px;
      height: 363px;
      border-radius: 4px;
    }
  }

  &_home {
    width: 464px;
    height: 363px;
    border-radius: 4px;
  }

  &__slide {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: top 50%;

    &-wrap {
      position: relative;
      min-width: 100%;
      width: 100%;
      height: 100%;
    }
  }

  &-nav {
    display: flex;

    &_history {
      margin-left: 152px;
    }

    &__links {
      display: flex;
      justify-content: stretch;
      width: 464px;
      margin-right: 35px;
      margin-top: 16px;

      &_home {
        margin-right: 16px;
        width: 368px;
      }

      &__link {
        height: 4px;
        width: inherit;
        background: #929292;
        cursor: pointer;

        &_curr {
          background: #FF7640;
        }
      }
    }

    &__buttons {
      z-index: 1;
      margin-top: -19px;

      &_home {
        margin-top: 16px;
      }

      &__button {
        background: #FFFFFF;
        border-radius: 2px;
        width: 40px;
        height: 40px;
        border: none;
        display: inline-flex;
        justify-content: center;
        align-items: center;

        &_can, &_history {
          margin-right: 16px;
        }

        &:hover, &:focus {
          background: #FFF1EC;
          border: 1px solid #FF7640;
        }
      }
    }
  }
}


@media screen and (max-width: 1281px) {
  .carousel {
    width: 580px;
    height: 410px;

    &-wrap {
      width: 580px;
      height: 410px;

      &_home {
        width: 364px;
        height: 284px;
      }
    }

    &_home {
      width: 364px;
      height: 284px;
    }

    &-nav {
      &_history {
        margin-left: 65px;
      }

      &__links {
        width: 363px;
        margin-right: 16px;

        &_home {
          width: 268px;
        }
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .carousel {
    width: 376px;
    height: 300px;

    &-wrap {
      width: 376px;
      height: 300px;

      &_home {
        width: 326px;
        height: 254px;
      }
    }

    &_home {
      width: 326px;
      height: 254px;
    }

    &-nav {
      &_history {
        margin-left: 50px;
      }

      &__links {
        width: 212px;
        margin-right: 16px;

        &_home {
          margin-top: 8px;
          margin-right: 34px;
        }
      }

      &__buttons {
        margin-top: -19px;
        z-index: 1;

        &_home {
          margin-top: 8px;
        }

        &__button {
          &:not(&_home):first-child {
            margin-right: 12px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .carousel {
    width: 95vw;
    max-height: 50vw;
    min-height: 280px;
    height: 50vw;

    &-wrap {
      width: 95vw;
      max-height: 50vw;
      min-height: 280px;
      height: 50vw;
    }

    &-nav {
      margin-bottom: 20px;

      &_history {
        margin-left: 0;
      }

      &__links {
        width: 95vw;
        margin-right: 0;
      }

      &__buttons {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 481px) {
  .carousel {
    width: 100vw;
    max-height: 60vw;
    min-height: 220px;
    height: 60vw;

    &-wrap {
      width: 100vw;
      max-height: 60vw;
      min-height: 220px;
      height: 60vw;

      &_home {
        width: 95vw;
      }
    }

    &_home {
      width: 95vw;
    }

    &-nav {
      &__links {
        width: 100vw;

        &_home {
          width: 95vw;
        }
      }
    }
  }
}
</style>
