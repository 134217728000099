<template>
  <div class="loading-skillet">
    <div class="loading-skillet__img"></div>
    <div class="loading-skillet__text">
      <div class="loading-skillet__text-title"></div>
      <div class="loading-skillet__text-line"></div>
      <div class="loading-skillet__text-line"></div>
      <div class="loading-skillet__text-line"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.loading-skillet {
  max-width: 1137px;
  width: 90vw;
  height: 30vw;
  margin: 50px auto;
  background: #E3F4FF;
  border-radius: 4px;
  padding: 5%;
  display: flex;
  justify-content: space-between;
  animation: blink 1s both infinite;

  &__img {
    width: 50%;
    height: 90%;
    background: #8ECEF9;
    border-radius: 4px;
  }

  &__text {
    width: 45%;

    &-title {
      background: #8ECEF9;
      border-radius: 4px;
      height: 10%;
      margin-bottom: 10%;
    }

    &-line {
      background: #8ECEF9;
      border-radius: 4px;
      height: 5%;
      margin-top: 5%;
    }
  }
}

@media screen and (max-width: 1920px) {
  .loading-skillet {
    height: 40vw;
  }
}

@media screen and (max-width: 1440px) {
  .loading-skillet {
    height: 50vw;
  }
}

@keyframes blink {
  0% {
    opacity: 1
  }
  50% {
    opacity: 0.4
  }
  100% {
    opacity: 1
  }
}
</style>
