import DateList from "@/entities/DateList";
import {diffInDays, getClearDateFromString, getPrettyDate} from "@/services/dateService";
import payConst from "@/constants/payConst";

export function createDateList(
  dateFrom,
  dateTo,
  countGuests,
  house,
  specialDays = null,
  discount = null,
  paymentType = payConst.fullPayment
) {
  let currDate = new Date(dateFrom);
  const endDate = new Date(dateTo);
  const dateList = new DateList();
  const diffDates = diffInDays(currDate, endDate) === 1;

  while (currDate < endDate) {
    let price;
    if (diffDates) {
      price = calculateSpecialPrice(currDate, specialDays) || house.weekendsPrice;
    } else {
      price = calculateSpecialPrice(currDate, specialDays) || calculateRegularPrice(currDate, house, discount);
    }

    price += addedPrice(countGuests);
    dateList.addToList(getPrettyDate(currDate), Math.floor(price / paymentType));
    currDate.setDate(currDate.getDate() + 1);
  }

  dateList.calcTotalPrice();
  return dateList;
}

function calculateSpecialPrice(currDate, specialDays) {
  const found = specialDays?.find(day => new Date(day.date).setHours(0, 0, 0, 0) === currDate.setHours(0, 0, 0, 0));
  return found ? found.price : null;
}

function calculateRegularPrice(currDate, house, discount) {
  const isWeekend = [5, 6].includes(currDate.getDay());
  let price = house[isWeekend ? 'weekendsPrice' : 'weekdaysPrice'];

  if (discount && currDate >= getClearDateFromString(discount.from) && currDate <= getClearDateFromString(discount.to)) {
    price = calculateDiscountPrice(discount, price, isWeekend);
  }

  return price;
}

function calculateDiscountPrice(discount, price, isWeekend) {
  if (discount.is_fix_price) {
    return discount[isWeekend ? 'weekendsPrice' : 'weekdaysPrice'];
  } else {
    return Math.floor(price - price * discount.percent / 100);
  }
}

function addedPrice(countGuests) {
  return countGuests >= 3 ? (countGuests - 2) * 1000 : 0;
}

export function checkComment(comment) {
  const regex = /^[А-яёЁ\w\s,.]*$/;
  return regex.test(comment);
}
