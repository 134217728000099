import {metaDescriptionTemplate, metaText, metaTitleTemplate} from "@/constants/metaConst"

function setTitle(text) {
    const elem = document.querySelector('title');

    if (elem && text) {
        elem.text = `${text} - ${metaTitleTemplate}`;
    } else {
        elem.text = metaTitleTemplate;
    }
}

function setDescription(text) {
    const elem = document.querySelector('meta[name="description"]');

    if (elem) {
        elem.setAttribute('content', text ?? metaDescriptionTemplate);
    }
}

export function setMetaData(titleText, descText) {
    setTitle(titleText);
    setDescription(descText);
}

export function setMetaDataByRouteName(routeName) {
    const textData = metaText[routeName] ?? metaText.default;

    if (textData) {
        setMetaData(textData.title, textData.description);
    }
}


