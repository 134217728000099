import { createApp } from 'vue'
import App from './App.vue'
import ClearApp from './ClearApp.vue'
import router from './router'
import store from './store'

const getMainApp = (pathname) => {
  if (pathname === '/booking-widget') {
    return ClearApp;
  } else {
    return App
  }
}

export const vue = createApp(getMainApp(window.location.pathname))
  .use(store)
  .use(router)
  .mount('#app');




