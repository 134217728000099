<template>
    <div class="not-found">
        <h1>404</h1>
        <h3>Такой страницы не существует</h3>
    </div>
</template>

<script>
export default {
    name: "NotFound"
}
</script>

<style scoped>
.not-found {
    text-align: center;
    margin-top: 30vh;
}
</style>
