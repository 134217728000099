<template>
  <Skillet v-if="isLoading"/>
  <div v-else>
    <header class="account__header">
      <h2 class="account__header__title">Бронирования</h2>
      <p class="account__header__subtitle">Заезд осуществляется с 15:00 и до 22:00, выезд — до 12:00. </p>
    </header>
    <OrderList type="curr" :orders="orders.curr"></OrderList>
    <OrderList type="next" :orders="orders.next"></OrderList>
    <OrderList type="prev" :orders="orders.prev"></OrderList>
  </div>
</template>

<script>
import OrderList from "@/components/blocks/OrdersList.vue";
import {httpApi} from "@/helper/httpHelper";
import Skillet from "@/components/blocks/Skillet.vue";

export default {
  name: "BookingAccount",
  components: {Skillet, OrderList},
  data() {
    return {
      isLoading: true,
      orders: {
        prev: {title: 'Прошедшие поездки', list: []},
        curr: {title: 'Текущие поездки', list: []},
        next: {title: 'Предстоящие поездки', list: []},
      },
    };
  },
  mounted() {
    this.getOrders();
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    userToken() {
      return this.$store.state.userToken;
    },
  },
  methods: {
    getOrders() {
      httpApi.get('orders/user?id=' + this.userId, {
        headers: {
          'Authorization': `Bearer ${this.userToken}`,
        }
      })
        .then(res => {
          const {orders} = res.data;
          this.orders.prev.list = orders.prev;
          this.orders.curr.list = orders.curr;
          this.orders.next.list = orders.next;
          this.isLoading = false;
        })
    }
  },
}
</script>

<style scoped lang="scss">
.account {
  &__header {
    margin: 60px auto;

    &__title {
      font-family: Tenor Sans, sans-serif;
      font-weight: normal;
      font-size: 48px;
      line-height: 120%;
      color: #181C16;
      margin-bottom: 4px;
    }

    &__subtitle {
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: #181C16;
    }
  }
}

@media screen and (max-width: 1025px) {
  .account__header {
    margin: 40px auto;
  }
}

@media screen and (max-width: 481px) {
  .account__header {
    &__title {
      font-size: 24px;
      line-height: 28px;
    }

    &__subtitle {
      font-size: 14px;
      line-height: 150%;
    }
  }
}
</style>
