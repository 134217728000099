import {createRouter, createWebHistory} from 'vue-router'
import Home from '../components/Home.vue'
import Houses from '../components/Houses.vue'
import Account from "@/components/Account";
import Contacts from "@/components/Contacts";
import Entertainments from "@/components/Entertainments";
import Faq from "@/components/Faq";
import Policy from "@/components/Policy";
import Booking from "@/components/Booking";
import Booked from "@/components/Booked";
import EmailConfirmation from "@/components/EmailConfirmation";
import EmailChanged from "@/components/EmailChanged";
import Offer from "@/components/Offer";
import NotFound from "@/components/NotFound";
import NotFoundRedirect from "@/components/NotFoundRedirect";
import {setMetaDataByRouteName} from "@/helper/metaHelper";
import {isAuth} from "@/services/authService";
import BookingWidget from "@/components/blocks/BookingWidget.vue";
import BnovoBookingPage from "@/components/BnovoBookingPage.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    // {
    //     path: '/login',
    //     name: 'Login',
    //     component: Home
    // },
    // {
    //     path: '/registration',
    //     name: 'Registration',
    //     component: Home
    // },
    // {
    //     path: '/reset-password',
    //     name: 'ResetPass',
    //     component: Home
    // },
    // {
    //     path: '/google-auth',
    //     name: 'GoogleAuth',
    //     component: Home
    // },
    {
        path: '/houses',
        name: 'Houses',
        component: Houses,
        beforeEnter() {
            return '/booking';
        }
    },
    {
        path: '/booking/:id',
        name: 'Booking',
        component: Booking,
        beforeEnter() {
            return '/booking';
        }
    },
    {
        path: '/booked/:id/:pay_id/:token/:postpaid?',
        name: 'Booked',
        component: Booked,
        beforeEnter() {
            return '/';
        }
    },
    {
        path: '/account',
        name: 'Account',
        component: Account,
        beforeEnter() {
            return '/';
        }
    },
    {
        path: '/contacts',
        name: 'Contacts',
        component: Contacts,
    },
    {
        path: '/entertainments',
        name: 'Entertainments',
        component: Entertainments,
    },
    {
        path: '/faq',
        name: 'Faq',
        component: Faq,
    },
    {
        path: '/policy',
        name: 'Policy',
        component: Policy,
    },
    {
        path: '/offer',
        name: 'Offer',
        component: Offer,
    },
    {
        path: '/email-confirmation/:email/:token',
        name: 'EmailConfirmation',
        component: EmailConfirmation,
    },
    {
        path: '/change-email-finish/:id/:email/:token',
        name: 'EmailChanged',
        component: EmailChanged,
    },
    {
        path: '/set404.php',
        name: 'set404php',
        redirect: '/404',
    },
    {
        path: '/404',
        name: '404',
        component: NotFound,
    },
    {
        path: '/booking-widget',
        name: 'BookingWidget',
        component: BookingWidget,
    },
    {
        path: '/booking',
        name: 'BnovoBooking',
        component: BnovoBookingPage,
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFoundRedirect',
        component: NotFoundRedirect,
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to) {
        if (to.hash) return {el: to.hash};
        window.scrollTo(0, 0);
    }
})

router.beforeEach((to) => {
    if (to.query.isTestMode) {
      sessionStorage.setItem('isTestMode', Boolean(to.query.isTestMode));
    }
    setMetaDataByRouteName(to.name);
})

export default router
