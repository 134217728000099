import store from "@/store";
import router from "@/router";
import {httpApi} from "@/helper/httpHelper";
import {httpCode} from "@/constants/httpConts";
import FrontErrorException from "@/errors/FrontErrorException";
import BackErrorException from "@/errors/BackErrorException";
import {vue} from "@/main";

export const setUser = (id, name, email, phone, avatar, token) => {
  if (id && token) {
    phone = phone ?? '';
    avatar = avatar ?? '';
    localStorage.setItem('userId', id);
    localStorage.setItem('userName', name);
    localStorage.setItem('userEmail', email);
    localStorage.setItem('userPhone', phone);
    localStorage.setItem('userAvatar', avatar);
    localStorage.setItem('userToken', token);

    store.commit('setUserId', id);
    store.commit('setUserName', name);
    store.commit('setUserEmail', email);
    store.commit('setUserPhone', phone);
    store.commit('setUserAvatar', avatar);
    store.commit('setUserToken', token);
  }
}

export const clearUser = () => {
  localStorage.removeItem('userId');
  localStorage.removeItem('userName');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('userPhone');
  localStorage.removeItem('userAvatar');
  localStorage.removeItem('userToken');

  store.commit('setUserId', '');
  store.commit('setUserName', '');
  store.commit('setUserEmail', '');
  store.commit('setUserPhone', '');
  store.commit('setUserAvatar', '');
  store.commit('setUserToken', '');
}

export const loadUser = () => {
  setUser(
    localStorage.getItem('userId'),
    localStorage.getItem('userName'),
    localStorage.getItem('userEmail'),
    localStorage.getItem('userPhone'),
    localStorage.getItem('userAvatar'),
    localStorage.getItem('userToken')
  );
}

export const logout = async () => {
  await httpApi.post('logout', {}, {
    headers: {
      'Authorization': `Bearer ${store.state.userToken}`,
    }
  })
    .then(() => {
      store.commit('setIsAuth', false);
      clearUser();
      router.push('/');
    });
}

export const initUser = async () => {
  try {
    checkUserOnFront();
    loadUser();
    store.commit('setIsAuth', true);
    await checkUserOnBack();
  } catch (err) {
    if (err instanceof FrontErrorException) {
      clearUser()
      store.commit('setIsAuth', false);
    } else if (err instanceof BackErrorException) {
      console.error(err);
      store.commit('setIsAuth', false);
      clearUser();
      router.push('/');
      vue.$refs.info.openInfo('Сессия устарела', 'Ваша сессия устарела, пожалуйста, авторизуйтесь заново')
    }
  }
}

export const checkUserOnFront = () => {
  if (!isAuth()) {
    throw new FrontErrorException('Auth error on front');
  }
};

export const checkUserOnBack = async () => {
  await httpApi.post('check-token', {id: localStorage.getItem('userId')}, {
    headers: {'Authorization': `Bearer ${localStorage.getItem('userToken')}`}
  })
    .catch(err => {
      if (httpCode.unauthorized === err.response.status) {
        throw new BackErrorException('Auth error on back');
      }
    })
}

export const isAuth = () => {
  return Boolean(localStorage.getItem('userId') && localStorage.getItem('userToken'));
}
