<template>
  <div class="policy">
    <h2 class="policy__title">{{policy.title}}</h2>
    <div v-html="policy.text"></div>
  </div>
</template>

<script>
import {httpApi} from "@/helper/httpHelper";

export default {
  name: "Policy",
  data() {
    return {
      siteUrl: 'https://budka.com',
      supportAddress: 'budka@email.com',
      isLoading: false,
      policy: {},
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.isLoading = true;

      httpApi.get( 'policy')
        .then(res => {
          this.policy = res.data;
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
  },
}
</script>

<style scoped lang="scss">
.policy {
  max-width: 944px;
  margin: 80px auto 40px;
  overflow: hidden;

  &__title {
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 36px;
    line-height: 120%;
    color: #181C16;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 1281px) {
  .policy {
    max-width: 896px;
    margin: 40px auto 40px;
  }
}

@media screen and (max-width: 1025px) {
  .policy {
    max-width: 668px;

    &__title {
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 769px) {
  .policy {
    max-width: 448px;
  }
}

@media screen and (max-width: 481px) {
  .policy {
    max-width: 288px;
    margin: 30px auto 50px;

    &__title {
      font-size: 24px;
      line-height: 28px;
      margin-bottom: 20px;
    }
  }
}
</style>
