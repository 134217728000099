<template>
  <div class="search-date">
    <Calendar inputId="search" :isOpen="isOpen" mode="range"></Calendar>
    <input class="search-date__input" :class="`search-date__input_${color}`" id="data-from" type="text"
           @click="openCalendar" readonly v-model="dateFrom" placeholder="Заезд">
    <div class="search-date__pipe" :class="`search-date__pipe_${color}`"></div>
    <input class="search-date__input" :class="`search-date__input_${color}`" id="data-to" type="text"
           @click="openCalendar" readonly v-model="dateTo" placeholder="Выезд">
    <span class="search-date__pipe" :class="`search-date__pipe_${color}`"></span>

    <GuestsSelector :color="color" type="search"></GuestsSelector>

    <router-link class="search-date__button" :class="`search-date__button_${color}`"
                 :to="{name: 'Houses', hash: page === 'Houses' ? '#house-list' : ''}">
      Выбрать дом<img src="../../assets/image/icon/search.png" alt="search.png">
    </router-link>
  </div>
</template>

<script>
import Calendar from "@/components/blocks/Сalendar";
import GuestsSelector from "@/components/blocks/GuestsSelector";

export default {
  name: "Search",
  props: {
    color: String,
  },
  data() {
    return {
      isOpen: false,
      page: this.$route.name
    }
  },
  methods: {
    openCalendar() {
      this.isOpen = false;
      setTimeout(() => {
        this.isOpen = true;
      }, 100)
    },
    getPrettyDate(date, formate = 'full') {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      let year = date.getFullYear().toString();
      if (formate === 'short') year = year.substr(-2);
      return `${day}.${month}.${year}`
    }
  },
  computed: {
    dateFrom() {
      if (this.$store.state.dateFrom) {
        return this.getPrettyDate(new Date(this.$store.state.dateFrom), 'short');
      }
    },
    dateTo() {
      if (this.$store.state.dateFrom) {
        return this.getPrettyDate(new Date(this.$store.state.dateTo), 'short');
      }
    },
    dates() {
      return {
        from: this.$store.state.dateFrom,
        to: this.$store.state.dateTo
      }
    }
  },
  watch: {
    dates() {
      this.isOpen = false;
    }
  },
  components: {
    GuestsSelector,
    Calendar
  }
}
</script>

<style lang="scss" scoped>
.search-date {
  height: 56px;
  display: flex;
  align-items: center;
  background: #FFFFFF;
  border-radius: 2px;
  position: relative;

  &__input {
    width: 200px;
    height: 100%;
    border: none;
    text-align: center;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #181C16;
    transition: background-color 300ms;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #929292;
    }

    &_blue {
      &:hover, &:focus {
        background: #E3F4FF;
      }
    }

    &_orange {
      &:hover, &:focus {
        background: #FFF1EC;
      }
    }
  }

  &__button {
    height: 100%;
    width: 264px;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid #FFFFFF;
    transition: border-color 300ms;

    & img {
      margin-left: 13px;
    }

    &_blue {
      background: #8ECEF9;

      &:hover, &:focus {
        border: 2px solid #8ECEF9;
      }

      &:active {
        border: 3px solid #E3F4FF;
        color: #E3F4FF;
      }
    }

    &_orange {
      background: #FF7640;

      &:hover, &:focus {
        border: 2px solid #FF7640;
      }

      &:active {
        border: 3px solid #FFF1EC;
        color: #FFF1EC;
      }
    }
  }

  &__pipe {
    height: 24px;
    margin: 0 20px;
    border-right: 2px solid;

    &_blue {
      border-color: #8ECEF9;
    }

    &_orange {
      border-color: #FF7640;
    }
  }
}



@media screen and (max-width: 1025px) {
  .search-date {
    &__input {
      width: 120px;
      margin: 0 16px;
    }

    &__button {
      width: 213px;
    }
  }
}

@media screen and (max-width: 769px) {
  .search-date {
    flex-wrap: wrap;
    width: 448px;

    &__pipe {
      margin: 0 15px;

      &_blue {
        border-color: #8ECEF9;
      }

      &_orange {
        border-color: #FF7640;
      }
    }

    &__input {
      width: 100px;
      margin: 0 14px;
    }

    &__button {
      width: 448px;
      margin-top: 8px;
    }
  }
}

@media screen and (max-width: 481px) {
  .search-date {
    width: 295px;

    &__pipe {
      margin: 0;
    }

    &__input {
      width: 88px;
      margin: 0 4px;
    }
  }
}
</style>
