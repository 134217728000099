export function diffInDays(date1, date2) {
  if (date1 instanceof Date && date2 instanceof Date) {
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    return Math.floor((date2 - date1) / (1000 * 60 * 60 * 24));
  }

  console.error('dateService::differenceInDays; date is not instance of Date');
}

export function getClearDateFromString(dateString) {
  return new Date(dateString).setHours(0, 0, 0, 0);
}

export function getPrettyDate(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().substring(2);
  return `${day}.${month}.${year}`;
}
