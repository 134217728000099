<template>
  <div v-if="order && !isLoading">
    <div class="booked">
      <div class="booked__one">
        <h3 class="booked__title">Бронирование № {{ order.id.toString().padStart(3, '0') }}</h3>
        <p class="booked__subtitle">Успешная оплата!</p>
        <p class="booked__info-pay">
          {{ successText }}
        </p>
      </div>
      <div class="booked__two">
        <img class="booked__img" :src="require('@/assets/image/booked/house.jpg')" alt="house-img">
        <div class="booked__img-info">
          <p>{{ getPrettyDate(order.from) }} - {{ getPrettyDate(order.to) }}</p>
          <p>{{ getDeclination(order.totalDays, 'сутки', 'суток', 'суток') }} / {{ order.totalPrice }} ₽</p>
        </div>
      </div>
      <div class="booked__three">
        <p class="booked__info">
          Не забудьте взять с собой залог в размере 5 000 рублей.
        </p>
        <p class="booked__info">Заезд осуществляется с 15:00 и до 22:00, выезд — до 12:00. Ранний заезд и поздний выезд
          оговаривается отдельно при наличии свободного домика.</p>
        <router-link to="/faq" class="link_orange">Правила отмены бронирования</router-link>
        <p class="booked__info">Бронирование сохранится в вашем личном кабинете, но на всякий случай мы выслали
          подтверждение вам на почту. </p>
        <router-link to="/account" class="link_orange">Посмотреть в личном кабинете</router-link>
      </div>
    </div>
  </div>
  <div v-else-if="errorMessage" class="placeholder-wrap">
    <h3 class="booked__title">Что-то пошло не так</h3>
    <div class="booked">
      <div class="booked__one">
        <p class="booked__subtitle">{{ errorMessage }}</p>
        <p class="booked__info-pay">Перенаправление на глувную через {{timer}} секунд</p>
      </div>
    </div>
  </div>
  <div v-else class="placeholder-wrap">
      <h3 class="booked__title">Не закрывайте страницу</h3>
      <p class="booked__subtitle">Подтверждаем оплату...</p>
      <Skillet />
  </div>
  <WayToUs></WayToUs>
  <BottomContacts></BottomContacts>
</template>

<script>
import Skillet from "@/components/blocks/Skillet";
import WayToUs from "@/components/blocks/WayToUs";
import BottomContacts from "@/components/blocks/BottomContacts";
import {httpApi} from "@/helper/httpHelper";

export default {
  name: "Booked",
  components: {BottomContacts, WayToUs, Skillet},
  data() {
    return {
      isLoading: false,
      order: null,
      errorMessage: null,
      timer: 10,
      intervalId: 0,
    }
  },
  mounted() {
    const {id, pay_id, token, postpaid} = this.$route.params;
    if (id && pay_id && token) this.getDataOrder(id, pay_id, token, postpaid);
  },
  unmounted() {
    if (this.intervalId) clearInterval(this.intervalId);
  },
  methods: {
    getDataOrder(id, pay_id, token, postpaid) {
      this.isLoading = true;

      httpApi.post(`orders/paid${postpaid ? '/' + postpaid : ''}`, {
          orderId: id,
          payId: pay_id,
          tokenPay: token,
      })
        .then(res => {
          this.order = res.data.order;
        })
        .catch(err => {
          this.errorMessage = err.response.data.errorMessage;
          console.error('Error! Request is failed.', err);

          this.intervalId = setInterval(() => {
            this.timer--;
            if (this.timer < 1) {
              clearInterval(this.intervalId);
              this.intervalId = 0;
              this.$router.push('/');
            }
          }, 1000)
        })

        .finally(() => {
          this.isLoading = false;
        })
    },
    getDeclination(val, one, two, three) {
      const valEnd = val % 10;
      let declination = one;
      if (+val >= 5 && +val <= 20 || valEnd === 0) {
        declination = three;
      } else if (valEnd >= 2 && valEnd <= 4) {
        declination = two;
      }
      return `${val} ${declination}`;
    },
    getPrettyDate(dateStr) {
      const date = new Date(dateStr);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().substr(-2);
      return `${day}.${month}.${year}`
    }
  },
  computed: {
    successText() {
        return Number(this.order.is_paid) === 2
          ? `Вы оплатили проживание полностью: ${this.order.totalPrice}₽ за ${this.getDeclination(this.order.totalDays, 'сутки', 'суток', 'суток')}.`
          : `Вы внесли предоплату ${Math.floor(this.order.totalPrice / 2)}₽. Оставшуюся сумму можно оплатить во время проживания. Мы принимаем наличную и безналичную оплату.`
    },
  },
}
</script>

<style scoped lang="scss">
.placeholder-wrap {
  max-width: 1135px;
  margin: 80px auto 144px;
}

.booked {
  max-width: 1135px;
  margin: 80px auto 144px;
  display: grid;
  grid-template-columns: 1fr 464px;
  grid-column-gap: 20px;

  &__one {

  }

  &__img {
    width: 464px;
    height: 278px;
    border-radius: 4px;
    object-fit: cover;

    &-info {
      margin-top: 20px;
      font-family: Tenor Sans, sans-serif;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #181C16;
      display: flex;
      justify-content: space-between;
    }
  }

  &__two {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 3;
  }

  &__three {

  }

  &__title {
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 36px;
    line-height: 120%;
    color: #181C16;
    margin-bottom: 8px;
  }

  &__subtitle {
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #181C16;
    margin-bottom: 20px;
  }

  &__info {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #181C16;
    margin-bottom: 8px;
    margin-top: 20px;

    &-pay {
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: #181C16;
    }
  }
}

@media screen and (max-width: 1281px) {
  .placeholder-wrap {
    max-width: 896px;
    margin: 60px auto 100px;
  }

  .booked {
    max-width: 896px;
    margin: 60px auto 100px;
    grid-template-columns: 1fr 364px;
    grid-column-gap: 90px;

    &__img {
      width: 364px;
      height: 218px;

      &-info {
        flex-direction: column;
        gap: 8px;
      }
    }

    &__subtitle {
      margin-bottom: 30px;
    }

    &__info {
      margin-top: 30px;
    }
  }
}

@media screen and (max-width: 1025px) {
  .placeholder-wrap {
    max-width: 668px;
    margin: 40px auto 80px;
  }

  .booked {
    max-width: 668px;
    grid-template-columns: 1fr 326px;
    grid-column-gap: 15px;
    margin: 40px auto 80px;

    &__img {
      width: 326px;
      height: 194px;
    }

    &__title {
      font-size: 24px;
      line-height: 28px;
    }

    &__subtitle {
      margin-bottom: 20px;
    }

    &__info {
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 769px) {
  .placeholder-wrap {
    max-width: 448px;
    margin: 40px auto 60px;
  }

  .booked {
    max-width: 448px;
    margin: 40px auto 60px;
    grid-template-columns: 1fr;

    &__img {
      width: 216px;
      height: 128px;

      &-info {
        margin-top: 0;
        font-size: 18px;
        line-height: 21px;
      }
    }

    &__two {
      grid-column-start: auto;
      grid-column-end: auto;
      grid-row-start: auto;
      grid-row-end: auto;
      display: flex;
      gap: 16px;
      align-items: center;
    }

    &__info {
      &-pay {
        margin-bottom: 20px;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 481px) {
  .placeholder-wrap {
    max-width: 288px;
  }

  .booked {
    max-width: 288px;

    &__img {
      width: 288px;
      height: 170px;
    }

    &__two {
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;
    }
  }
}

</style>
