<template>
  <BnovoBooking/>
  <ScrollToBottom/>
  <BottomContacts/>
</template>

<script>
import BnovoBooking from "@/components/blocks/BnovoBooking.vue";
import ScrollToBottom from "@/components/blocks/ScrollToBottom.vue";
import BottomContacts from "@/components/blocks/BottomContacts.vue";

export default {
  name: 'BnovoBookingPage',
  components: {BottomContacts, ScrollToBottom, BnovoBooking}
}
</script>

<style scoped lang="scss">

</style>
