export default class DateList {
  constructor() {
    this.list = {};
    this.totalPrice = 0;
  }

  addToList(date, price) {
    this.list[date] = price;
  }

  calcTotalPrice() {
    this.totalPrice = Object.values(this.list).reduce((acc, price) => acc + price, 0);
  }
}
