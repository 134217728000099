<template>

</template>

<script>
export default {
    name: "NotFoundRedirect",
    beforeCreate() {
        location.href = '/set404.php';
    }
}
</script>

<style scoped>

</style>
