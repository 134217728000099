<template>
  <div class="guest-count" :class="`guest-count_${type}`">
    <input @focusout="closeDropGuests(200)" @click="openDropGuests" type="text" readonly
           :value="countGuestStr" :class="`guest-input guest-input_${type} guest-input_search_${color}`">
    <transition name="fade">
      <div v-show="isDrop" class="guest-count__drop" :class="`guest-count__drop_${color}`">
        <p @click="setCountGuests(1)" class="guest-count__drop__elem" :class="`guest-count__drop__elem_${color}`">
          1 человек
          <img v-if="countGuest === 1" :src="require(`@/assets/image/check-${color}.svg`)" alt="check.svg">
        </p>
        <p @click="setCountGuests(2)" class="guest-count__drop__elem" :class="`guest-count__drop__elem_${color}`">
          2 человека
          <img v-if="countGuest === 2" :src="require(`@/assets/image/check-${color}.svg`)" alt="check.svg">
        </p>
        <p @click="setCountGuests(3)" class="guest-count__drop__elem" :class="`guest-count__drop__elem_${color}`">
          3 человека
          <img v-if="countGuest === 3" :src="require(`@/assets/image/check-${color}.svg`)" alt="check.svg">
        </p>
        <p @click="setCountGuests(4)" class="guest-count__drop__elem" :class="`guest-count__drop__elem_${color}`">
          4 человека
          <img v-if="countGuest === 4" :src="require(`@/assets/image/check-${color}.svg`)" alt="check.svg">
        </p>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "GuestsSelector",
  props: {
    color: String,
    type: String,
  },
  data() {
    return {
      isDrop: false,
    }
  },
  methods: {
    openDropGuests() {
      this.isDrop = true;
    },
    closeDropGuests(delay = 0) {
      setTimeout(() => {
        this.isDrop = false;
      }, delay)
    },
    setCountGuests(count) {
      this.$store.commit('setCountGuests', count);
      this.isDrop = false;
    },
  },
  computed: {
    countGuestStr() {
      const count = this.countGuest;
      return `Гостей ${count}`;
    },
    countGuest() {
      return +this.$store.state.countGuests;
    },
  },
}
</script>

<style scoped lang="scss">
.guest-count {
  position: relative;
  height: inherit;

  &__drop {
    bottom: -206px;
    width: 200px;
    position: absolute;
    padding: 12px 4px;
    background: #FFFFFF;
    z-index: 1;

    &_blue {
      border: 2px solid #8ECEF9;
    }

    &_orange {
      border: 2px solid #FF7640;
    }

    &__elem {
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      color: #929292;
      padding: 6px 16px;
      margin-bottom: 8px;
      cursor: pointer;
      border-radius: 2px;
      display: flex;
      justify-content: space-between;

      &:last-child {
        margin: 0;
      }

      &:hover {
        color: #181C16;
      }

      &_blue {
        &:hover, &:focus {
          background: #E3F4FF;
        }
      }

      &_orange {
        &:hover, &:focus {
          background: #FFF1EC;
        }
      }
    }
  }
}

.guest-input {
  transition: background-color 300ms;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &_search {
    width: 200px;
    height: 100%;
    border: none;
    text-align: center;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #181C16;

    &::placeholder {
      color: #929292;
    }

    &_blue {
      &:hover, &:focus {
        background: #E3F4FF;
      }
    }

    &_orange {
      &:hover, &:focus {
        background: #FFF1EC;
      }
    }
  }

  &_booking {
    padding: 8px 24px;
    font-weight: bold;
    font-size: 14px;
    line-height: 150%;
    color: #8ECEF9;
    text-align: center;
    border: 2px solid #8ECEF9;
    border-top: none;
    border-radius: 2px;
    background: #FFFFFF;
    transition: background-color 0.3s, color 0.3s;
    width: 159px;
  }
}

@media screen and (max-width: 1025px) {
  .guest-input {
    &_booking {
      padding: 8px 6px;
      width: 125px;
    }

    &_search {
      width: 120px;
      margin: 0 16px;
    }
  }
}

@media screen and (max-width: 769px) {
  .guest-count {
    &__drop {
      right: 0;
    }
  }

  .guest-input {
    &_booking {
      width: 100%;
    }

    &_search {
      width: 100px;
      margin: 0 14px;
    }
  }
}

@media screen and (max-width: 481px) {
  .guest-count {
    &_booking {
      width: 50%;
    }
  }

  .guest-input {
    &_booking {
      border: 2px solid #8ECEF9;
      border-left: none;
    }

    &_search {
      width: 88px;
      margin: 0 4px;
    }
  }
}
</style>
