<template>
  <div>
    <p>{{ error }}</p>
    <button class="button_orange email-conf-button"
            @click.prevent="sendEmailConfirm"
            :disabled="timerMailSend"
    >
      Повторить отправку письма {{ timerMailSend !== 0 ? timerMailSend : '' }}
    </button>
  </div>
</template>

<script>
import {httpApi} from "@/helper/httpHelper";

export default {
  name: "SendEmailButton",
  props: {
    email: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
  },
  created() {
    this.init();
  },
  data() {
    return {
      timerMailSend: 0,
      timerLimit: 60,
    }
  },
  methods: {
    init() {
      const diffSec = this.getSecFromLastAttempt();

      if (diffSec) {
        this.startTimer(diffSec);
      }
    },
    getSecFromLastAttempt() {
      const lastAttempt = localStorage.getItem('email-conf-time');
      let diffNow = 0;

      if (lastAttempt !== null) diffNow = Math.floor((new Date().getTime() - lastAttempt) / 1000);
      if (diffNow > 0 && diffNow <= this.timerLimit) {
        return Math.floor(this.timerLimit - diffNow);
      }

      return 0;
    },
    startTimer(diffTime) {
      this.timerMailSend = diffTime;
      const intervalId = setInterval(() => {
        this.timerMailSend -= 1;

        if (this.timerMailSend < 1) {
          clearInterval(intervalId);
          localStorage.removeItem('email-conf-time');
        }
      }, 1000);
    },
    sendEmailConfirm() {
      const diffSec = this.getSecFromLastAttempt();

      if (!diffSec) {
        localStorage.setItem('email-conf-time', new Date().getTime().toString());
        this.startTimer(this.timerLimit);

        httpApi({
          method: 'POST',
          url: 'repeat-email',
          data: {email: this.email},
        })
          .then(() => {
            this.$emit('closeModal');
            this.$root.$refs.info.openInfo(
              'Подтверждение электронной почты',
              'Вам на почту отправлено письмо, перейдите по ссылке из письма для завершения регистрации'
            );
          })
          .catch(err => {
            console.error(err);
          })
      }
    },
  }
}
</script>

<style scoped lang="scss">
.email-conf-button {
  font-size: 14px;
  line-height: 150%;
  margin-top: 4px;
  width: 100%;
  height: 41px;
}
</style>
