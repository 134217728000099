<template>
  <div class="auth-modal auth-modal_reg">
    <img @click="$emit('closeModal')" class="auth-modal__close" src="@/assets/image/modal-cross.svg"
         alt="modal-cross.svg">
    <h3 class="auth-modal__title">Регистрация</h3>
    <p class="auth-modal__subtitle">Для продолжения бронирования, необходимо зарегистрироваться на сайте.</p>

    <form @submit.prevent="sendForm">
      <div class="auth-modal__inputs">
        <input required class="input" type="text" v-model="name" placeholder="Имя">
        <p class="input__error" v-for="error in errors.name">{{ error }}</p>

        <input required class="input" type="email" v-model="email" placeholder="Почта">
        <p class="input__error" v-for="error in errors.email">{{ error }}</p>

        <input required class="input" type="password" v-model="password" placeholder="Пароль" minlength="8">
        <p class="input__error" v-for="error in errors.password">{{ error }}</p>

        <p class="input__error" v-for="error in errors.total">{{ error }}</p>
      </div>
      <div class="auth-modal__text-politic__wrap">
        <input required class="auth-modal__input-politic" id="politic" type="checkbox">
        <label for="politic" class="auth-modal__text-politic">
          Я согласен с
          <router-link to="/policy" @click="$emit('closeModal')">политикой обработки персональных данных</router-link>
          и
          <router-link to="/offer" @click="$emit('closeModal')">офертой</router-link>
        </label>
      </div>

      <button :disabled="isLoading" class="button_blue auth-modal__send" type="submit">
        <SvgPreloader v-if="isLoading"/>
        <span v-else>Зарегистрироваться</span>
      </button>
    </form>
    <div class="auth-modal__reg-with" v-if="isTestMode">
      <p>Зарегистрироваться используя</p>
      <div class="reg-with-icons">
        <button type="button" @click.prevent="redirectToGoogle" class="clear-button">
          <img :src="require('@/assets/image/icon/google.svg')" alt="google">
        </button>
      </div>
    </div>
    <p class="auth-modal__to-login">
      Уже есть аккаунт?
      <span class="auth-modal__to-login__button" @click="setLoginType">Войти</span>
    </p>
  </div>
</template>

<script>
import authTypes from "@/constants/authTypes";
import {httpApi} from "@/helper/httpHelper";
import SvgPreloader from "@/components/blocks/SvgPreloader.vue";

export default {
  name: "Registration",
  components: {SvgPreloader},
  data() {
    return {
      isLoading: false,
      name: '',
      email: '',
      password: '',
      errors: {},
    }
  },
  computed: {
    isTestMode() {
      return Boolean(sessionStorage.getItem('isTestMode'));
    },
  },
  methods: {
    redirectToGoogle() {
      this.isLoading = true;
      this.errors = {};

      httpApi.get('auth/google/get-redirect-url')
        .then(res => {
          window.location.replace(res.data);
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    setLoginType() {
      this.$emit('changeType', authTypes.login);
    },
    sendForm() {
      this.isLoading = true;
      this.errors = {};

      httpApi({
        url: 'register',
        method: 'POST',
        data: {name: this.name, email: this.email, password: this.password},
      })
        .then(() => {
          this.$emit('closeModal');

          this.name = '';
          this.email = '';
          this.password = '';

          localStorage.setItem('email-conf-time', new Date().getTime().toString());

          this.$root.$refs.info.openInfo(
            'Подтверждение электронной почты',
            'Вам на почту отправлено письмо, перейдите по ссылке из письма для завершения регистрации'
          );
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
}
</script>

<style scoped lang="scss">
.clear-button {
  background: none;
  border: none;
  cursor: pointer;
}

.auth-modal {
  &_reg {
    height: 708px;
  }

  &__subtitle {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #292B33;
    width: 60%;
    margin: 0 0 16px 0;
  }

  &__input-politic {
    width: 20px;
    height: 20px;
  }

  &__text-politic {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #181C16;
    text-align: left;
    display: block;
    width: 295px;
    margin-left: 10px;

    & a {
      color: #FF7640;;
    }

    &__wrap {
      display: flex;
      margin: 12px 0;
    }
  }

  &__reg-with {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #181C16;
    margin-top: 15px;
  }
}

.reg-with-icons {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

@media screen and (max-width: 481px) {
  .auth-modal {
    &_reg {
      height: 594px;
    }

    &__subtitle {
      width: 80%;
      margin: 0 0 16px 0;
    }

    &__text-politic {
      width: 246px;

      & a {
        display: inline;
      }
    }
  }
}
</style>
