<template>
  <div class="booking-wrap">
    <div id="booking_iframe">
      <div
        id="bn_iframe"
        style="font-family: 'Proxima nova', 'Helvetica Neue', 'Cera Pro Medium', Arial, Helvetica, sans-serif; position: absolute; right: 0; bottom: 0; font-size: 12px; line-height: 1em; opacity: .5; z-index: 10; margin-top: 10px;"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BnovoBooking',
  mounted() {
    this.loadExternalScript(
      'https://widget.reservationsteps.ru/iframe/library/dist/booking_iframe.js',
      this.initBookingWidget
    );
  },
  methods: {
    loadExternalScript(src, callback) {
      const script = document.createElement('script');
      script.src = src;
      script.onload = callback;
      document.head.appendChild(script);
    },
    initBookingWidget() {
      const BnovoBookFrame = new BookingIframe({
        html_id: 'booking_iframe',
        uid: '85917ed6-ced7-4340-a5fb-ad0a16e75485',
        lang: 'ru',
        width: 'auto',
        height: 'auto',
        rooms: '',
        IsMobile: '0',
        scroll_to_rooms: '0',
      });
      BnovoBookFrame.init();
    }
  }
};
</script>

<style scoped lang="scss">
#booking_iframe {
  position: relative;
  z-index: 0;
}

.booking-wrap {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 132px;
}

@media screen and (max-width: 1281px) {
  .booking-wrap {
    padding: 0 46px;
  }
}

@media screen and (max-width: 1025px) {
  .booking-wrap {
    padding: 0 50px;
  }
}

@media screen and (max-width: 769px) {
  .booking-wrap {
    padding: 0 16px;
  }
}
</style>
