<template>
  <div>
    <ModalInfo ref="info"></ModalInfo>
    <AuthModal ref="auth"></AuthModal>
    <div class="top-menu__container">
      <nav class="top-menu">
        <router-link to="/" @click="toggleMobMenu(true)">
          <img class="logo" :src="require('@/assets/image/logo.webp')" alt="logo.webp">
        </router-link>
        <div class="top-menu__links">
          <router-link class="top-menu__link" :class="{ 'top-menu__link_active': routeName === 'BnovoBooking' }"
                       to="/booking">Проживание
          </router-link>
          |
          <router-link class="top-menu__link" :class="{ 'top-menu__link_active': routeName === 'Entertainments' }"
                       to="/entertainments">Развлечения
          </router-link>
          |
          <router-link class="top-menu__link" :class="{ 'top-menu__link_active': routeName === 'Faq' }"
                       to="/faq">Вопросы
          </router-link>
        </div>
        <div class="top-menu__icons">
          <a :href="links.instagram" target="_blank"><img :src="require('@/assets/image/icon/instagram.svg')" alt="instagram.svg"></a>
        </div>
        <img v-show="!openMobMenu" @click="toggleMobMenu(openMobMenu)" class="top-menu__button_mob"
             src="./assets/image/icon/mob-menu.svg" alt="mob-menu.svg">
        <img v-show="openMobMenu" @click="toggleMobMenu(openMobMenu)" class="top-menu__button_mob"
             src="./assets/image/mob-menu-cross.svg" alt="modal-cross.svg">
        <transition name="fade">
          <div v-show="openMobMenu" @click.self="toggleMobMenu(true)" class="top-menu__wrap">
            <nav v-show="openMobMenu" class="top-menu__wrap_mobile">
              <div class="top-menu__links_mobile">
                <router-link class="top-menu__link" :class="{ 'top-menu__link_active': routeName === 'BnovoBooking' }"
                             to="/booking" @click="toggleMobMenu(true)">Проживание
                </router-link>
                <span>|</span>
                <router-link class="top-menu__link"
                             :class="{ 'top-menu__link_active': routeName === 'Entertainments' }"
                             to="/entertainments" @click="toggleMobMenu(true)">Развлечения
                </router-link>
                <span>|</span>
                <router-link class="top-menu__link" :class="{ 'top-menu__link_active': routeName === 'Faq' }"
                             to="/faq" @click="toggleMobMenu(true)">Вопросы
                </router-link>
              </div>
              <div class="top-menu__icons_mobile">
                <a :href="links.instagram" target="_blank"><img :src="require('@/assets/image/icon/instagram.svg')" alt="instagram.svg"></a>
              </div>
            </nav>
          </div>
        </transition>
      </nav>
    </div>
    <router-view/>
  </div>

  <div class="footer__wrap">
    <footer class="footer">
      <div class="footer__links">
        <router-link class="footer__link" to="/offer">Публичная оферта</router-link>
        <router-link class="footer__link" to="/policy">Политика конфидециальности</router-link>
        <router-link class="footer__link" to="/contacts">Контакты</router-link>
        <router-link class="footer__link" to="/faq">Вопросы</router-link>
      </div>

      <a :href="`mailto:${rentEmail}`" class="footer__phone">{{ rentEmail }}</a>
    </footer>
    <p class="copyright">© ИП Бабкин Н. А., 2021—{{(new Date()).getFullYear()}}</p>
  </div>
</template>

<script>
import ModalInfo from "@/components/blocks/ModalInfo";
import AuthModal from "@/components/blocks/AuthModal/AuthModal";
import authTypes from "@/constants/authTypes";
import {initUser} from "@/services/authService";

export default {
  name: 'App',
  data() {
    return {
      openMobMenu: false,
    }
  },
  mounted() {
    initUser();
  },
  methods: {
    clickAuth() {
      if (this.isAuth) {
        if (this.openMobMenu) this.toggleMobMenu(this.openMobMenu)
        this.$router.push('/account');
      } else {
        if (this.openMobMenu) this.toggleMobMenu(this.openMobMenu)
        this.$refs.auth.openAuth(authTypes.registration)
      }
    },
    toggleMobMenu(isOpen) {
      if (isOpen) {
        this.openMobMenu = false;
        document.body.classList.remove('no-scroll')
      } else {
        this.openMobMenu = true;
        document.body.classList.add('no-scroll');
      }
    }
  },
  computed: {
    isAuth() {
      return this.$store.state.isAuth;
    },
    routeName() {
      return this.$route.name
    },
    rentEmail() {
      return this.$store.state.rentEmail;
    },
    links() {
      return this.$store.state.links;
    },
    avatar() {
      return this.$store.state.userAvatar;
    },
    accountImgName() {
      if (this.avatar) {
        return this.avatar;
      }

      const icon = this.isAuth ? 'auth-login' : 'auth';

      return require(`@/assets/image/icon/${icon}.svg`);
    },
  },
  components: {
    AuthModal,
    ModalInfo,
  }
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tenor+Sans&display=swap');

.no-scroll {
  overflow: hidden;
}

a {
  text-decoration: none;
}

html, body {
  font-family: 'Raleway', sans-serif;
  font-feature-settings: 'pnum' on, 'lnum' on;
  min-height: 100vh;
}

.reset-button {
  background-color: inherit;
  border: none;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

.top-menu {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;
  margin: 0 auto;
  height: 90px;
  padding: 0 152px;

  &__container {
    box-shadow: 0 1px 2px rgba(24, 28, 22, 0.1);
  }

  &__button_mob {
    display: none;
    width: 42px;
    height: 42px;
  }

  & .logo {
    width: 138px;
    height: 70px;
  }

  &__wrap {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    height: calc(100vh - 50px);
    background: rgba(36, 36, 36, 0.43);
    z-index: 2;
    display: none;

    &_mobile {
      position: absolute;
      top: 0;
      left: 0;
      background: #FFFFFF;
      justify-content: space-between;
      align-items: center;
      height: 83px;
      width: 100%;
      padding: 0 50px;
      z-index: 5;
      display: none;
    }
  }

  &__links {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    text-align: center;

    &_mobile {
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      display: flex;
      justify-content: space-between;
      width: 80vw;
      align-items: center;
    }
  }

  &__link {
    display: inline-block;
    color: #181C16;
    margin: 0 50px;
    position: relative;
    transition: color 300ms;

    &_active {
      color: #FF7640;
    }

    &:before {
      display: block;
      position: absolute;
      content: '';
      border-bottom: 2px solid #FF7640;
      bottom: -18px;
      left: 50%;
      width: 0;
      opacity: 0;
      transition: width 300ms, left 300ms, opacity 300ms;
    }

    &:hover:before {
      left: 0;
      width: 100%;
      opacity: 1;
    }
  }

  &__icons {
    display: flex;
    justify-content: center;
    align-items: center;

    & a {
      display: flex;
    }

    &__auth {
      margin-right: 21px;
      cursor: pointer;
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }

    &_mobile {
      font-weight: normal;
      display: flex;
    }
  }
}

.copyright {
  width: 1440px;
  margin: 0 auto;
  padding: 0 154px 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #181C16;
}

.footer {
  display: flex;
  padding: 36.5px 154px 10px;
  justify-content: space-between;
  align-items: center;
  width: 1440px;
  margin: 0 auto;

  &__wrap {
    box-shadow: 0px -1px 2px rgba(24, 28, 22, 0.1);
  }

  &__link {
    margin-right: 40px;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #181C16;

    &:last-child {
      margin-right: 0;
    }
  }

  &__phone {
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #181C16;
  }
}

.button {
  &_blue {
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #8ECEF9;
    border: 2px solid #8ECEF9;
    border-radius: 2px;
    background: #FFFFFF;
    transition: background-color 0.3s, color 0.3s;
    cursor: pointer;

    &:hover {
      background: #E3F4FF;
    }

    &:focus {
      background: #E3F4FF;
    }

    &:active {
      background: #8ECEF9;
      color: #FFFFFF;
    }

    &:disabled {
      color: #929292;
      background: #EFEFEF;
      border-color: #D5D5D5;
      cursor: auto;
    }
  }

  &_orange {
    background: #FFFFFF;
    border: 2px solid #FF7640;
    box-sizing: border-box;
    border-radius: 2px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #FF7640;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
    cursor: pointer;

    &:hover, &:focus {
      background: #FFF1EC;
    }

    &:active {
      background: #FF7640;
      color: #FFFFFF;
    }

    &:disabled {
      color: #929292;
      background: #EFEFEF;
      border: 2px solid #EFEFEF;

    }
  }
}

.h2 {
  font-family: Tenor Sans, sans-serif;
  font-weight: normal;
  font-size: 48px;
  line-height: 120%;
  color: #181C16;
}

.h3 {
  font-family: Tenor Sans, sans-serif;
  font-weight: normal;
  font-size: 36px;
  line-height: 120%;
  color: #181C16;
}

.text {
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  color: #181C16;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

.fade-price-enter-active, .fade-price-leave-active {
  transition: all 1s;
}

.fade-price-enter-from, .fade-price-leave-to {
  opacity: 0;
  //transform: scaleX(0);
  transform: translate(-30%, -30%) scale(0.4, 0);
  //height: 0;
}

.link_orange {
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #FF7640;
  transition: border-color 0.3s, font-weight 0.1s;
  border-bottom: 1px solid #FFFFFF;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid #FF7640;
  }

  &:active, &:focus {
    font-weight: bold;
    border-bottom: 1px solid #FFFFFF;
  }

  &:disabled {
    font-weight: normal;
    color: #959ea9;
    border-bottom: none;
    cursor: default;
  }
}

.date-list {
  position: relative;
  display: flex;
  margin-left: 0.3em;

  &_booking {
    margin: 0;
  }

  &:hover .date-list__modal, &:focus .date-list__modal {
    display: block;
  }

  &__modal {
    display: none;
    position: absolute;
    bottom: 105%;
    background: #E3F4FF;
    padding: 0.3rem;
    border-radius: 4px;
    border: 2px solid #8ECEF9;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      box-sizing: border-box;
      width: 4px;
      height: 4px;
      border: 4px solid transparent;
      border-top: 4px solid #8ECEF9;
      top: 101%;
      left: 6.5%;
    }

    &::after {
      content: '';
      position: absolute;
      width: 35px;
      height: 20px;
      top: 100%;
      left: 0;
    }
  }

  &__table {
    display: block;
    border-spacing: 5px;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 85px;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #FF7640;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #D4D4D4;
    }

    &__date {
      font-weight: 700;
      text-align: right;
    }

    &__price {
      text-align: right;
    }
  }
}

@media screen and (max-width: 1441px) {
  .copyright {
    max-width: 1440px;
    width: inherit;
  }

  .footer {
    max-width: 1440px;
    width: inherit;

    &__link:last-child {
      margin-right: 40px;
    }
  }
}

@media screen and (max-width: 1281px) {
  .top-menu {
    padding: 0 64px;
    height: 60px;

    & .logo {
      width: 98px;
      height: 50px;
    }

    &__link {
      margin: 0 30px;

      &:before {
        bottom: -8px;
      }
    }
  }

  .copyright {
    padding: 0 72px 7px;
  }

  .footer {
    padding: 24.5px 72px 7px;

    &__link {
      font-size: 14px;
    }

    &__phone {
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
    }
  }

  .text {
    font-size: 14px;
    line-height: 150%;
  }
}

@media screen and (max-width: 1025px) {
  .top-menu {
    padding: 0 50px;
    height: 50px;

    &__wrap {
      display: block;

      &_mobile {
        display: flex;
      }
    }

    &__button_mob {
      display: block;
    }

    & .logo {
      width: 88px;
      height: 44px;
    }

    &__links {
      display: none;

      &_mobile {
        width: 87%;
      }
    }

    &__icons {
      display: none;

      &_mobile {
        & a {
          display: flex;
        }
      }
    }

    &__link {
      margin: 0 6vw;
    }

    &_mobile {

    }
  }

  .copyright {
    padding: 0 54px 15px 45.5px;
  }

  .footer {
    padding: 30px 54px 5px 45.5px;

    &__link {
      margin-right: 8px;

      &:last-child {
        margin-right: 9px;
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .top-menu {
    padding: 0 16px;
  }

  .text {
    font-size: 18px;
  }

  .copyright {
    text-align: center;
  }

  .footer {
    flex-direction: column;

    &__link {
      font-size: 14px;
      padding: 10px;
    }

    &__links {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    &__phone {
      padding: 10px;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
    }
  }

  .top-menu {
    &__wrap {
      &_mobile {
        height: 77px;
        padding: 0 16px;
      }
    }

    &__links {
      &_mobile {
        font-size: 14px;
      }
    }

    &__link {
      margin: 0 20px;
    }

    &__icons {
      margin-right: 20px;

      &_mobile {
        display: flex;
        align-items: center;

        & a {
          display: flex;
        }
      }
    }
  }
}

@media screen and (max-width: 481px) {
  .top-menu {
    &__wrap {
      &_mobile {
        justify-content: flex-start;
        height: 235px;
        flex-direction: column;
      }
    }

    &__links {
      &_mobile {
        flex-direction: column;
        margin-top: 12px;
        margin-bottom: 16px;

        & span {
          display: none;
        }
      }
    }

    &__link {
      padding: 8px;
      margin-bottom: 8px;
    }
  }

  .date-list {
    &_booking {
      margin-left: 0.3em;
    }
  }
}

</style>
