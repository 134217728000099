import axios from 'axios';
import {apiUrl} from "@/constants/httpConts";

export const httpApi = axios.create({
    baseURL: apiUrl,
    withCredentials: true,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Accept': 'application/json',
    }
});
