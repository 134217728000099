import { createStore } from 'vuex'
import {apiUrl} from "@/constants/httpConts";

export default createStore({
  state: {
    isAuth: false,
    userId: '',
    userName: '',
    userEmail: '',
    userPhone: '',
    userAvatar: '',
    userToken: '',
    dateFrom: sessionStorage.getItem('dateFrom') ?? '',
    dateTo: sessionStorage.getItem('dateTo') ?? '',
    countGuests: 2,
    apiUrl,
    supportEmail: 'support@budka-village.ru',
    rentEmail: 'hello@budka-village.ru',
    rentPhone: '+7 000 000 00 00',
    mapCoordinates: '55.986247, 35.386186',
    links: {
      vk: 'https://vk.com/budkavillage',
      telegram: 'https://t.me/budka',
      instagram: 'https://instagram.com/budka.village',
    },
  },
  mutations: {
    setIsAuth(state, bool) {
      state.isAuth = Boolean(bool);
    },
    setUserId(state, id) {
      state.userId = id;
    },
    setUserName(state, name) {
      state.userName = name;
    },
    setUserEmail(state, email) {
      state.userEmail = email;
    },
    setUserPhone(state, phone) {
      state.userPhone = phone;
    },
    setUserAvatar(state, avatar) {
      state.userAvatar = avatar;
    },
    setUserToken(state, token) {
      state.userToken = token;
    },
    setDateFrom(state, date) {
      state.dateFrom = date;
      sessionStorage.setItem('dateFrom', date)
    },
    setDateTo(state, date) {
      state.dateTo = date;
      sessionStorage.setItem('dateTo', date)
    },
    setCountGuests(state, count) {
      state.countGuests = count;
    },
  },
  actions: {
  },
  modules: {
  },
})
