<template>
  <input :id="inputId + '-hidden'" v-model="date" class="calendar-input-hidden">
</template>

<script>
import flatpickr from "flatpickr";
import '@/assets/style/calendar.css';
import {Russian} from "flatpickr/dist/l10n/ru.js"

export default {
  name: "Calendar",
  props: {
    inputId: String,
    isOpen: Boolean,
    mode: String,
    disable: Array,
  },

  data() {
    const mainThis = this;
    return {
      calendar: {},
      dateMax: '',
      dateMin: '',
      monthArray: {
        ru: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        en: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      },
      date: null,
      dateFromAndTo: [],
      config: {
        defaultDate: [],
        locale: Russian,
        minDate: new Date().fp_incr(1),
        position: 'below',
        nextArrow: `<img src="${require('@/assets/image/arrows/calendar-next.svg')}" alt="next">`,
        prevArrow: `<img src="${require('@/assets/image/arrows/calendar-back.svg')}" alt="prev">`,
        maxDate: new Date().fp_incr(90), // 90 days from now
        monthSelectorType: 'static',

        onDayCreate(dObj, dStr, config, dayElem) {
          mainThis.monthArray.ru.forEach((elem, index) => {
            if (dayElem.getAttribute('aria-label').match(elem)) {
              const engDate = dayElem.getAttribute('aria-label').replace(elem, mainThis.monthArray.en[index]);
              dayElem.dataset.date = mainThis.getDateEn(new Date(engDate));
              return;
            }
          })

          mainThis.dateFromAndTo.forEach(elem => {
            if (dayElem.dataset.date === elem.from) {
              dayElem.classList.add('flatpickr-disabled-start');
            } else if (dayElem.dataset.date === elem.to) {
              dayElem.classList.add('flatpickr-disabled-end');
            }
          });

          if (dayElem.classList.contains('today')) {
            dayElem.innerHTML += '<span class="today-circle"></span>';
            if (config.config.mode === 'range') dayElem.classList.add('flatpickr-disabled-end');
          }

          // добавляем к начальному дню инфо "только заезд"
          if (new Date(dayElem.dataset.date).setHours(0) === config.config.minDate.setHours(0)) {
            if (config.config.mode === 'range') dayElem.classList.add('flatpickr-disabled-end');
          }

          if (dayElem.classList.contains('flatpickr-disabled-start') && dayElem.classList.contains('flatpickr-disabled-end')) {
            dayElem.classList.add('flatpickr-disabled');
          }
        },
        onChange(date, dateStr, config) {
          if (dateStr !== '' && dateStr.split(' — ').length === 1) {
            config.selectedDateElem.classList.add('flatpickr-disabled');
            const days = config.days.childNodes;

            let isBlocked = false;
            for (let i = 0; i < days.length; i++) {
              if (config.selectedDateElem.classList.contains('flatpickr-disabled-start') && dateStr < days[i].dataset.date) {
                isBlocked = true;
              }

              if (isBlocked) days[i].classList.add('flatpickr-disabled');
              if (days[i].classList.contains('flatpickr-disabled-start') && dateStr < days[i].dataset.date) {
                isBlocked = true;
              }
            }
            if (isBlocked) config.nextMonthNav.classList.add('flatpickr-disabled');

            isBlocked = false;
            for (let i = days.length - 1; i >= 0; i--) {
              if (config.selectedDateElem.classList.contains('flatpickr-disabled-end') && dateStr > days[i].dataset.date) {
                isBlocked = true;
              }

              if (isBlocked) days[i].classList.add('flatpickr-disabled');
              if (days[i].classList.contains('flatpickr-disabled-end') && dateStr > days[i].dataset.date) {
                isBlocked = true;
              }
            }
            if (isBlocked) config.prevMonthNav.classList.add('flatpickr-disabled');
          } else {
            if (config.config.maxDate !== undefined && (config.currentYear === config.config.maxDate.getFullYear()
                ? !(config.currentMonth + 1 > config.config.maxDate.getMonth())
                : !(config.currentYear > config.config.maxDate.getFullYear()))
            ) {
              config.nextMonthNav.classList.remove('flatpickr-disabled');
            }

            if (config.config.minDate !== undefined && (config.currentYear === config.config.minDate.getFullYear()
                ? !(config.currentMonth <= config.config.minDate.getMonth())
                : !(config.currentYear < config.config.minDate.getFullYear()))
            ) {
              config.prevMonthNav.classList.remove('flatpickr-disabled');
            }
          }
        },
        onReady(date, dateStr, config) {
          if (config.config.mode === 'range') {
            const DomElem = '<div class="calendar-description"><p class="calendar-description-from">Возможен только заезд</p><p class="calendar-description-to">Возможен только выезд</p></div>';
            config.calendarContainer.insertAdjacentHTML('beforeend', DomElem);
          }
        },
        onOpen(date, dateStr, config) {
          if (mainThis.mode === 'range' && mainThis.inputId === 'search') {
            config.setDate([mainThis.$store.state.dateFrom, mainThis.$store.state.dateTo])
          }
        },
        onClose() {
        },
        onMonthChange(date, dateStr, config) {

        }
      },
      calendarMode: this.mode ?? '',
    };
  },

  mounted() {
    this.initCalendar(this.calendarMode, this.disable);
  },

  updated() {
    if (this.isOpen) this.calendar.open();
  },

  methods: {
    initCalendar(mode, disable = []) {
      if (mode) {
        this.config.mode = mode;
      }
      if (disable.length) {
        this.config.disable = this.parseDisabledDate(disable);
      }

      this.calendar = flatpickr(`#${this.inputId + '-hidden'}`, this.config);
    },

    parseDisabledDate(disable) {
      const disableParsed = [];
      disable.forEach(item => {

        const {from, to} = item;
        const dateFrom = new Date(from);
        let dateTo = (new Date(to));

        const diffDays = (+dateTo - +dateFrom) / (1000 * 3600 * 24);
        if (diffDays >= 2) {
          this.dateFromAndTo.push({from: this.getDateEn(dateFrom), to: this.getDateEn(dateTo)});
          const dataFrom = new Date(dateFrom.setDate(dateFrom.getDate() + 1));
          const dataTo = new Date(dateTo.setDate(dateTo.getDate() - 1));
          disableParsed.push({from: this.getDateEn(dataFrom), to: this.getDateEn(dataTo)});
        } else {
          this.dateFromAndTo.push({from: item.from, to: item.to});
        }
      })
      return disableParsed;
    },

    getDateEn(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    },

    setBlockOnDate() {

    }
  },

  watch: {
    date() {
      if (this.mode === 'range') {
        const dateArray = this.date.split(' — ');
        if (dateArray.length === 2) {
          this.$store.commit('setDateFrom', dateArray[0]);
          this.$store.commit('setDateTo', dateArray[1]);
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
#wrap-calendar {
}

.white {
  background: #000;
}

.calendar-input-hidden {
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
</style>
