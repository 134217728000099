<template>
  <section>
    <header class="account__settings-title-wrap">
      <h3 class="account__settings-title">Настройки</h3>
      <p class="link_orange account__settings-logout" @click="logout">Выйти из аккаунта</p>
    </header>
    <div class="account__settings">
      <form class="account__settings-pass" @submit.prevent="sendNewPass">
        <h4 class="account__settings-subtitle">Изменить пароль</h4>
        <label class="account__settings-label">
          <p class="account__settings-text">Создайте новый пароль</p>
          <input required minlength="8" class="account__settings-input" v-model="password" type="password"
                 placeholder="Новый пароль">
          <p class="account__settings-input__error" v-for="error in errors.password">{{ error }}</p>
        </label>
        <label class="account__settings-label">
          <p class="account__settings-text">Повторите новый пароль</p>
          <input required minlength="8" class="account__settings-input" v-model="password_r" type="password"
                 placeholder="Новый пароль">
          <p class="account__settings-input__error" v-for="error in errors.password_r">{{ error }}</p>
        </label>
        <label class="account__settings-label">
          <p class="account__settings-text">Введите старый пароль</p>
          <input required class="account__settings-input" v-model="password_old" type="password"
                 placeholder="Старый пароль">
          <p class="account__settings-input__error" v-for="error in errors.password_old">{{ error }}</p>
          <p class="account__settings-input__error" v-for="error in errors.total">{{ error }}</p>
        </label>
        <button class="button_blue account__settings-button" :disabled="isLoading">
          <SvgPreloader v-if="isLoading" />
          <span v-else class="account__settings-button__text">Изменить пароль</span>
        </button>
        <p class="link_orange" @click="forgotPass">Забыли пароль?</p>
      </form>
      <div class="account__settings-pd">
        <form class="account__settings-name" @submit.prevent>
          <h4 class="account__settings-subtitle">Имя</h4>
          <input class="account__settings-change-field" v-model="name" :readonly="!isChanging.name" type="text"
                 :disabled="isLoadingField.name">
          <p class="account__settings-input__error" v-for="error in errors.name">{{ error }}</p>
          <button class="reset-button link_orange" @click="redactFields('name')" :disabled="isLoadingField.name">
            {{ getFieldText('name') }}
          </button>
        </form>
        <form class="account__settings-phone" @submit.prevent>
          <h4 class="account__settings-subtitle">Телефон</h4>
          <input class="account__settings-change-field" :readonly="!isChanging.phone" maxlength="18" type="text"
                 :disabled="isLoadingField.phone" @input="updatePhone" :value="phone" @change="checkPhone(phone)">
          <p class="account__settings-input__error" v-for="error in errors.phone">{{ error }}</p>
          <button class="reset-button link_orange" @click="redactFields('phone')" :disabled="isLoadingField.phone">
            {{ getFieldText('phone') }}
          </button>
        </form>
        <form class="account__settings-email" @submit.prevent>
          <h4 class="account__settings-subtitle">Почта</h4>
          <input class="account__settings-change-field" v-model="email" :readonly="!isChanging.email" type="email"
                 :disabled="isLoadingField.email">
          <p class="account__settings-input__error" v-for="error in errors.email">{{ error }}</p>
          <button class="reset-button link_orange" @click="redactFields('email')" :disabled="isLoadingField.email">
            {{ getFieldText('email') }}
          </button>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import {logout} from "@/services/authService";
import {setMaskOnPhone, setCursorPosition} from "@/services/phoneService";
import {httpApi} from "@/helper/httpHelper";
import SvgPreloader from "@/components/blocks/SvgPreloader.vue";

export default {
  name: "SettingsAccount",
  components: {SvgPreloader},
  mounted() {
    this.name = this.userName;
    this.phone = this.userPhone === '' ? 'Не указан' : this.userPhone;
    this.email = this.userEmail;
  },
  data() {
    return {
      errors: {},
      password: '',
      password_r: '',
      password_old: '',
      isLoading: false,
      name: '',
      phone: '',
      email: '',
      isChanging: {name: false, phone: false, email: false},
      isLoadingField: {name: false, phone: false, email: false},
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
    userName() {
      return this.$store.state.userName;
    },
    userEmail() {
      return this.$store.state.userEmail;
    },
    userPhone() {
      return this.$store.state.userPhone;
    },
    userToken() {
      return this.$store.state.userToken;
    },
  },
  methods: {
    logout() {
      logout()
    },
    forgotPass() {
      this.$root.$refs.auth.openAuth('reset-pass');
    },
    redactFields(field) {
      const status = this.isChanging[field];
      if (!status) {
        this.isChanging[field] = true;
      } else {
        this.submitHandler(field)
      }
    },
    getFieldText(field) {
      return this.isChanging[field] ? (this.isLoadingField[field] ? 'Отправляем...' : 'Сохранить') : 'Изменить'
    },
    checkPhone(phone) {
      const regex = /^\+7 \(([\d|_]{3})\) ([\d|_]{3})-([\d|_]{2})-([\d|_]{2})$/;
      const result = regex.test(phone);
      if (result) delete this.errors.phone;
      else this.errors.phone = ['Неверный номер телефона'];
      return result;
    },
    updatePhone(e) {
      if (e.inputType === 'deleteContentBackward' || e.inputType === 'deleteContentForward') {
        this.phone = e.target.value
        return;
      }

      let originVal = true;
      const val = e.data;
      const oldVal = this.phone

      if (val !== null && /\d/.test(val)) {
        if (this.phone + val !== e.target.value) {
          this.phone = e.target.value;
          originVal = false;
        }
        this.phone = setMaskOnPhone(this.phone, val, originVal);
      } else {
        this.phone = oldVal === '' ? ' ' : '';
        this.phone = oldVal;
      }
      setCursorPosition(e.target);
    },
    sendNewPass() {
      this.isLoading = true;
      this.errors = {};

      httpApi.post('new-password-lk', {
        password: this.password,
        password_r: this.password_r,
        password_old: this.password_old,
        email: this.userEmail,
      }, {
        headers: {
          'Authorization': `Bearer ${this.userToken}`,
        }
      })
        .then(() => {
          this.$root.$refs.info.openInfo(
            '',
            'Вы успешно сменили пароль'
          );
          this.password = '';
          this.password_r = '';
          this.password_old = '';
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    sendName() {
      if (this.name === this.userName) {
        this.isChanging.name = false;
        return;
      }
      this.isLoadingField.name = true;

      httpApi.post('change-name', {
        name: this.name,
      }, {
        headers: {
          'Authorization': `Bearer ${this.userToken}`,
        }
      })
        .then(() => {
          this.$store.commit('setUserName', this.name);
          localStorage.setItem('userName', this.name);

          this.$root.$refs.info.openInfo(
            'Смена имени',
            'Вы успешно сменили имя'
          );
          this.isChanging.name = false;
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        })
        .finally(() => {
          this.isLoadingField.name = false;
        })
    },
    sendPhone() {
      if (this.phone === this.userPhone) {
        this.isChanging.phone = false;
        return;
      }
      this.isLoadingField.phone = true;

      httpApi.post('change-phone', {
        phone: this.phone,
      }, {
        headers: {
          'Authorization': `Bearer ${this.userToken}`,
        }
      })
        .then(() => {
          this.$store.commit('setUserPhone', this.phone);
          localStorage.setItem('userPhone', this.phone);

          this.$root.$refs.info.openInfo(
            'Смена телефонного номера',
            'Вы успешно сменили телефонный номер'
          );
          this.isChanging.phone = false;
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        })
        .finally(() => {
          this.isLoadingField.phone = false;
        })
    },
    sendEmail() {
      if (this.email === this.userEmail) {
        this.isChanging.email = false;
        return;
      }
      this.isLoadingField.email = true;

      httpApi.post('change-email-start', {
        email: this.email,
      }, {
        headers: {
          'Authorization': `Bearer ${this.userToken}`,
        }
      })
        .then(() => {
          this.$root.$refs.info.openInfo(
            'Смена электронной почты',
            'Вы запустили процедуру смены электронной почты. На вашу новую почту отправлено сообщение с инструкцией.'
          );
          this.isChanging.email = false;
          logout();
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        })
        .finally(() => {
          this.isLoadingField.email = false;
        })
    },
    async submitHandler(field) {
      this.errors = {};
      switch (field) {
        case 'name': {
          this.sendName();
          break;
        }
        case 'phone': {
          this.sendPhone();
          break;
        }
        case 'email': {
          this.sendEmail();
        }
      }
    },
  },
}
</script>

<style scoped lang="scss">
.account {
  &__settings {
    display: flex;
    gap: 111px;

    &-title {
      font-family: Tenor Sans, sans-serif;
      font-weight: normal;
      font-size: 48px;
      line-height: 120%;
      color: #181C16;

      &-wrap {
        display: flex;
        align-items: center;
        gap: 150px;
        margin-bottom: 40px;
      }
    }

    &-logout {
      cursor: pointer;
    }

    &-subtitle {
      font-family: Tenor Sans, sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #181C16;
      margin-bottom: 16px;
    }

    &-text {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #181C16;
      margin-top: 8px;
    }

    &-input {
      background: #FFFFFF;
      border: 2px solid #8ECEF9;
      border-radius: 2px;
      padding: 10px 16px;
      width: 272px;
      transition: color 0.3s;
      outline: none;

      &:hover, &:focus {
        color: #8ECEF9;
      }

      &:hover::placeholder, &:focus::placeholder {
        color: #8ECEF9;
      }

      &::placeholder {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
        transition: color 0.3s;
      }

      &__error {
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        color: #FA5956;
        margin: 4px 0;
      }
    }

    &-button {
      padding: 9px;
      margin: 8px 0 20px;
      width: 272px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;

      &__text {
        font-weight: normal;
        font-size: 14px;
        line-height: 150%;
      }
    }

    &-pd {
      display: grid;
      gap: 15px;
      grid-template-columns: repeat(2, 1fr);
      //grid-auto-rows: minmax(90px, 110px);
      align-items: self-start;
    }

    &-name {
      grid-row-start: 1;
      grid-row-end: 3;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-phone {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-email {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-pass {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &-change-field {
      outline: none;
      margin: 8px 0;
      padding: 5px;
      border: 2px solid #8ECEF9;
      border-radius: 2px;
      transition: padding .3s, border-color .3s;

      &:read-only {
        padding: 0;
        border: 2px solid #FFFFFF;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .account {
    &__settings {
      gap: 130px;

      &-title {
        &-wrap {
          gap: 110px;
        }
      }

      &-input {
        width: 212px;
      }

      &-button {
        width: 212px;
      }

      &-pd {
        grid-template-columns: 1fr;
      }

      &-name {
        grid-row-start: auto;
        grid-row-end: auto;
      }
    }
  }
}
@media screen and (max-width: 769px) {
  .account {
    &__settings {
      flex-direction: column;
      gap: 40px;

      &-title {
        font-size: 36px;

        &-wrap {
          flex-direction: column;
          align-items: flex-start;
          gap: 16px;
          margin-bottom: 30px;
        }
      }

      &-label {
        width: 100%;
      }

      &-input {
        width: 100%;
      }

      &-button {
        width: 100%;
      }

      &-pass {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
</style>
