<template>
  <div class="account">
    <ModalAccount ref="modal"></ModalAccount>

    <BookingAccount class="account-container"/>
    <div class="account__blue-space"></div>
    <SettingsAccount class="account-container"/>
    <div class="account__blue-space"></div>
    <SupportAccount class="account-container"/>
  </div>
</template>

<script>
import OrderList from "@/components/blocks/OrdersList";
import ModalAccount from "@/components/blocks/ModalAccount";
import BookingAccount from "@/components/blocks/Account/BookingAccount.vue";
import SettingsAccount from "@/components/blocks/Account/SettingsAccount.vue";
import SupportAccount from "@/components/blocks/Account/SupportAccount.vue";

export default {
  name: "Account",
  components: {SupportAccount, SettingsAccount, BookingAccount, ModalAccount, OrderList},
}
</script>

<style scoped lang="scss">
.account {
  &-container {
    margin: 60px auto;
    max-width: 1440px;
    padding: 0 152px;
  }

  &__blue-space {
    height: 17px;
    background: #E3F4FF;
  }
}

@media screen and (max-width: 1281px) {
  .account-container {
    padding: 0 64px;
  }
}

@media screen and (max-width: 1025px) {
  .account-container {
    padding: 0 50px;
  }
}

@media screen and (max-width: 769px) {
  .account-container {
    margin: 40px auto;
    max-width: 332px;
    padding: 0;
  }
}

@media screen and (max-width: 481px) {
  .account-container {
    max-width: 288px;
  }
}
</style>
