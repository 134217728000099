<template>
  <div class="booking__promo">
    <input type="text" class="booking__input booking__promo-input" placeholder="Промокод" v-model="promoCode"
           @change="handlerChangePromo" :disabled="isBlockedPromoCodeInput">
    <button class="button_blue booking__promo-button" @click.prevent="handlerClickPromo" :disabled="isBlockedPromoCodeButton">
      {{ isActivePromoCode ? 'Отменить' : 'Применить' }}
    </button>
  </div>
  <sup class="booking__input__error" v-for="error in errors">{{ error }}</sup>
</template>

<script>
import {httpApi} from "@/helper/httpHelper";

export default {
  name: 'PromoCode',
  emits: ['setDiscount', 'clearDiscount'],
  props: {
    houseId: {
      type: Number,
      default: 0,
    }
  },
  data() {
    return {
      errors: [],
      promoCode: '',
      isActivePromoCode: false,
      isBlockedPromoCodeInput: false,
      isBlockedPromoCodeButton: false,
    };
  },
  methods: {
    handlerChangePromo() {
      if (this.promoCode && !this.isActivePromoCode) this.errors = 'Нажмите кнопку "Применить"';
      else if (!this.promoCode) this.errors = '';
    },
    handlerClickPromo() {
      this.errors = {}
      if (this.isActivePromoCode) {
        this.deactivatePromo();
      } else if (this.promoCode) {
        if (this.checkPromoCode(this.promoCode, this.isActivePromoCode)) this.sendPromoCode();
      } else {
        this.errors = 'Введите промокод';
      }
    },
    sendPromoCode() {
      this.toggleBlockedPromo();

      httpApi.post('promo-code', {
        'userPromoCode': this.promoCode,
        'userToken': this.userToken,
        'houseId': this.houseId,
      })
        .then(res => {
          this.$emit('setDiscount', res.data.discounts);
          this.activatePromo();
        })
        .catch(err => {
          this.errors = err.response.data.errors?.userPromoCode;
          this.toggleBlockedPromo(false);
        })
    },
    activatePromo() {
      this.isActivePromoCode = true;
      this.isBlockedPromoCodeInput = true;
      this.isBlockedPromoCodeButton = false;
      this.errors = '';
    },
    deactivatePromo() {
      this.isActivePromoCode = false;
      this.toggleBlockedPromo(false);
      this.promoCode = '';
      this.errors = '';
      this.$emit('clearDiscount')
    },
    toggleBlockedPromo(state = true) {
      this.isBlockedPromoCodeInput = state;
      this.isBlockedPromoCodeButton = state;
    },
    checkPromoCode(promoCode, isActive) {
      const regex = /^\w*$/;
      const result = regex.test(promoCode);

      if (result) {
        if (isActive || promoCode.length === 0) this.errors = '';
        else if (promoCode) this.errors = 'Проверяем';
        else this.errors = 'Нажмите кнопку "Применить"';
      } else this.errors = 'Промокод не действителен';

      return result;
    },
  }
}
</script>

<style scoped lang="scss">
.booking {
  &__input {
    padding: 10px 16px;
    background: #FFFFFF;
    border: 2px solid #8ECEF9;
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    margin-bottom: 7px;
    transition: color 0.3s;

    &::placeholder {
      transition: color 0.3s;
    }

    &:hover, &:focus {
      outline: none;
      color: #8ECEF9;
    }

    &:hover::placeholder, &:focus::placeholder {
      color: #8ECEF9;
    }

    &:disabled {
      background: #EFEFEF;
      border-color: #D5D5D5;
      color: #929292;

      &:hover, &:focus {
        color: #929292;
      }
    }

    &__error {
      font-weight: normal;
      font-size: 11px;
      line-height: 13px;
      color: #FA5956;
    }
  }

  &__promo {
    display: flex;
    align-items: stretch;
    margin-bottom: 7px;

    &-input {
      margin: 0;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
      border-right: none;

    }

    &-button {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      padding: 8px;
    }
  }
}
</style>
