<template>
  <section>
    <h3 class="account__support-title">Поддержка</h3>
    <div class="account__support">
      <div>
        <h4 class="account__support-subtitle">Вопросы бронирования</h4>
        <a :href="`mailto:${rentEmail}`" class="account__support-text">{{ rentEmail }}</a>
        <div class="account__support-social">
          <a target="_blank" :href="links.telegram"><img :src="require('@/assets/image/icon/telegram.svg')"
                                                         alt="telegram.svg"></a>
          <a target="_blank" :href="links.instagram"><img :src="require('@/assets/image/icon/instagram.svg')"
                                                          alt="instagram.svg"></a>
          <a target="_blank" :href="links.vk"><img :src="require('@/assets/image/icon/vk.svg')" alt="vk.svg"></a>
        </div>
      </div>
      <div>
        <h4 class="account__support-subtitle">Проблемы на сайте</h4>
        <a :href="`mailto:${supportEmail}`" class="account__support-text">{{ supportEmail }}</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "SupportAccount",
  computed: {
    supportEmail() {
      return this.$store.state.supportEmail;
    },
    rentEmail() {
      return this.$store.state.rentEmail;
    },
    links() {
      return this.$store.state.links;
    },
  },
}
</script>

<style scoped lang="scss">
.account__support {
  display: flex;
  gap: 102px;

  &-title {
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 36px;
    line-height: 120%;
    color: #181C16;
    margin-bottom: 30px;
  }

  &-subtitle {
    font-family: Tenor Sans, sans-serif;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #181C16;
    margin-bottom: 16px;
  }

  &-text {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 120%;
    color: #181C16;
  }

  &-social {
    display: flex;
    gap: 18px;
    margin-top: 12px;
  }
}

@media screen and (max-width: 1025px) {
  .account__support {
    gap: 60px;
  }
}

@media screen and (max-width: 769px) {
  .account__support {
    flex-direction: column;
    gap: 40px;

    &-title {
      font-size: 24px;
      line-height: 28px;
    }
  }
}

@media screen and (max-width: 481px) {
  .account__support-subtitle {
    font-size: 18px;
    line-height: 21px;
  }
}
</style>
