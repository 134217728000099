<template>
  <div class="main-search__bg">
    <section class="main-search">
      <h1 class="main-search__title">Отдых за городом</h1>
      <BookingWidget :url="bookingUrl"/>
    </section>
  </div>
  <section class="welcome__wrap">
    <article class="welcome">
      <div class="welcome__line"></div>
      <h2 class="welcome__title h2">BUDKA — это домики на опушке леса. </h2>
      <p class="text">Отдых души, головы и тела.<br> Пространство для работы и творчества.</p>
    </article>
  </section>

  <section class="history">
    <h2 class="h2 history__title history__title_mob">История</h2>
    <p class="text history__text_mob">
      BUDKA появилась во время пандемии, когда мы перестали быть привязаны к городу и получили возможность жить и
      работать ближе к природе.
    </p>
    <Carousel id="history" :slides="historySlides" @change-slide="changeTextSlide"></Carousel>
    <article class="history__text-wrap">
      <div class="history__text">
        <h2 class="h2 history__title">История</h2>
        <p class="text">
          BUDKA появилась во время пандемии, когда мы перестали быть привязаны к городу и получили возможность жить и
          работать ближе к природе.
        </p>
        <div class="history__line"></div>
        <p class="text history__text">В 100м от хвойного леса в чистом поле мы построили два (пока что два) панорамных
          домика.</p>
      </div>
      <router-link class="button_orange history__button" to="/booking">Выбрать дом</router-link>
    </article>
  </section>

  <section class="can">
    <article class="can__text-wrap">
      <div>
        <h2 class="can__title h2">Здесь <br class="can__title__br">ты сможешь:</h2>
        <p class="text can__text">{{ canSlides[canSlideIndex].text }}</p>
      </div>
      <router-link class="button_orange can__button" to="/booking">Выбрать дом</router-link>
    </article>
    <Carousel id="can" :slides="canSlides" @change-slide="changeTextSlide"></Carousel>
    <router-link class="button_orange can__button can__button_mob" to="/booking">Выбрать дом</router-link>
  </section>

  <section class="doing">
    <div class="doing__img_1 doing__grid">
      <img class="doing__grid-img" src="../assets/image/entertainment/bike.jpg" alt="bike.jpg">
      <p class="doing__grid-text">Велосипеды</p>
    </div>
    <article class="doing__text-wrap">
      <h2 class="h2 doing__title">Чем заняться</h2>
      <p class="text">Разные виды развлечений.<br>Многие из них бесплатны.</p>
      <router-link class="button_orange doing__button" to="/entertainments">Выбрать</router-link>
    </article>
    <div class="doing__img_2 doing__grid">
      <img class="doing__grid-img" src="../assets/image/entertainment/table_games.jpg" alt="table_games.jpg">
      <p class="doing__grid-text">Настолки</p>
    </div>
    <div class="doing__img_3 doing__grid">
      <img class="doing__grid-img" src="../assets/image/entertainment/grill.jpg" alt="grill.jpg">
      <p class="doing__grid-text">Еда на углях</p>
    </div>
    <div class="doing__img_4 doing__grid">
      <img class="doing__grid-img" src="../assets/image/entertainment/book.jpg" alt="book.jpg">
      <p class="doing__grid-text">Книги</p>
    </div>
    <div class="doing__img_5 doing__grid">
      <img class="doing__grid-img" src="../assets/image/entertainment/bath.jpg" alt="bath.jpg">
      <p class="doing__grid-text">Купель</p>
    </div>
  </section>
  <router-link class="button_orange doing__button doing__button_mob" to="/entertainments">Выбрать</router-link>

  <ScrollToBottom></ScrollToBottom>

  <BottomContacts></BottomContacts>
</template>

<script>
import Search from "@/components/blocks/Search";
import Carousel from "@/components/blocks/Carousel";
import BottomContacts from "@/components/blocks/BottomContacts";
import ScrollToBottom from "@/components/blocks/ScrollToBottom";
import BookingWidget from "@/components/blocks/BookingWidget.vue";

export default {
  name: 'Home',
  computed: {
    bookingUrl() {
      return window.location.href + 'booking';
    },
  },
  data() {
    return {
      canSlideIndex: 0,
      historySlideIndex: 0,
      canSlides: [
        {
          url: require('@/assets/image/carousel-doing/1.jpg'),
          text: 'Жить в современном барнхаусе с видом на бескрайние поля и густой хвойный лес'
        }, {
          url: require('@/assets/image/carousel-doing/2.jpg'),
          text: 'Насладиться местными пейзажами на велосипедных прогулках'
        }, {
          url: require('@/assets/image/carousel-doing/3.jpg'), //////////
          text: 'Позавтракать на веранде'
        }, {
          url: require('@/assets/image/carousel-doing/4.jpg'),
          text: 'Сбежать от столичного шума и смога в самый экологически чистый район Московской области'
        }, {
          url: require('@/assets/image/carousel-doing/5.jpg'), ////////
          text: 'Устроить себе утреннюю пробежку, вдыхая запах теплой, еще влажной от росы травы'
        }, {
          url: require('@/assets/image/carousel-doing/6.jpg'),
          text: 'Искупаться в Верхнерузском водохранилище'
        }, {
          url: require('@/assets/image/carousel-doing/7.jpg'),
          text: 'Устроить себе барбекю вечер на веранде с лучшим видом - на звездное небо'
        },
      ],
      historySlides: [
        {url: require('@/assets/image/carousel-history/1.jpg')},
        {url: require('@/assets/image/carousel-history/2.jpg')},
        {url: require('@/assets/image/carousel-history/3.jpg')},
        {url: require('@/assets/image/carousel-history/4.jpg')},
        {url: require('@/assets/image/carousel-history/5.jpg')},
        {url: require('@/assets/image/carousel-history/6.jpg')},
        {url: require('@/assets/image/carousel-history/7.jpg')},
        {url: require('@/assets/image/carousel-history/8.jpg')},
      ],
    }
  },
  methods: {
    changeTextSlide(payload) {
      if (payload.id === 'can') this.canSlideIndex = payload.slide;
      else if (payload.id === 'history') this.historySlideIndex = payload.slide;
    }
  },
  components: {
    BookingWidget,
    ScrollToBottom,
    BottomContacts,
    Carousel,
    Search
  }
}
</script>

<style lang="scss" scoped>
.main-search {
  &__bg {
    background: center url("../assets/image/main/background.webp") no-repeat;
    background-size: cover;
  }

  min-height: 641px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 152px;
  max-width: 1440px;
  margin: 0 auto;

  &__title {
    font-family: 'Tenor Sans', sans-serif;
    font-weight: normal;
    font-size: 72px;
    line-height: 120%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin: 167px 0 63px;
  }
}

.welcome {
  margin: 60px 0 79px 152px;

  &__wrap {
    max-width: 1440px;
    margin: 0 auto;
  }

  &__line {
    border-bottom: 2px solid #181C16;
    width: 172px;
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 20px;
  }
}

.history {
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto 82px;

  &__text {
    width: 320px;

    &-wrap {
      margin-left: 80px;
      margin-right: 152px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__title {
    margin-bottom: 20px;

    &_mob {
      display: none;

      & + p {
        display: none;
      }
    }
  }

  &__line {
    border-bottom: 2px solid #181C16;
    width: 175px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  &__button {
    padding: 17px;
    width: 272px;
    margin-top: 20px;
  }
}

.can {
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto 120px;

  &__text-wrap {
    margin-left: 152px;
    margin-right: 61px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    margin-bottom: 40px;
  }

  &__button {
    padding: 17px;
    width: 272px;
    margin-top: 20px;

    &_mob {
      display: none;
    }
  }
}

.doing {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(12, calc(100% / 12 - 16px + (16px / 12)));
  grid-template-rows: 308px 308px;
  overflow: hidden;
  max-width: 1440px;
  margin: 0 auto 80px;

  &__grid {
    position: relative;

    &:hover &-text, &:focus &-text {
      opacity: 1;
    }

    &-img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &-text {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 40%;
      opacity: 0;
      transition: opacity 300ms;
      text-align: center;
    }
  }

  &__img_1 {
    grid-column-start: 1;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  &__text-wrap {
    margin-left: 120px;
    margin-right: 139px;
    grid-column-start: 7;
    grid-column-end: 13;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  &__img_2 {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  &__img_3 {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  &__img_4 {
    grid-column-start: 7;
    grid-column-end: 9;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  &__img_5 {
    grid-column-start: 9;
    grid-column-end: 13;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  &__title {
    margin-bottom: 20px;
  }

  &__button {
    margin-top: 40px;
    display: block;
    padding: 17px;
    width: 272px;

    &_mob {
      display: none;
    }
  }
}

@media screen and (max-width: 1280px) {
  .main-search {
    padding: 0 64px;;
  }

  .welcome {
    margin: 50px 0 80px 64px;
  }

  .history {
    &__title {
      font-size: 36px;
      line-height: 120%;
      margin-bottom: 12px;
    }

    &__text-wrap {
      margin-left: 92px;
      margin-right: 27px;
    }

    &__line {
      margin-top: 30px;
      margin-bottom: 20px;
    }

    &__button {
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
    }
  }

  .can {
    &__text-wrap {
      margin-left: 64px;
      margin-right: 12px;
      width: 100%;
    }

    &__title {
      font-size: 36px;
      line-height: 120%;
      margin-bottom: 20px;
    }

    &__button {
      padding: 17px 79.5px;
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
    }
  }

  .doing {
    &__text-wrap {
      margin-left: 92px;
      margin-right: 67px;
    }

    &__button {
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      padding: 9.5px;
      width: 211px;
    }
  }
}

@media screen and (max-width: 1025px) {
  .main-search {
    padding: 0 20px;

    &__title {
      font-size: 48px;
      line-height: 120%;
      margin: 60px 0 40px;
    }
  }

  .welcome {
    &__title {
      font-weight: normal;
      font-size: 36px;
      line-height: 120%;
    }
  }

  .history {
    &__title {
      margin-bottom: 10px;
    }

    &__text-wrap {
      margin-left: 50px;
      margin-right: 54px;
    }

    &__button {
      padding: 9px 42.5px;
    }
  }

  .can {
    &__text-wrap {
      margin-left: 50px;
      margin-right: 18px;
    }

    &__button {
      padding: 9px 42.5px;
    }
  }

  .doing {
    grid-template-rows: 308px 308px 308px;
    overflow: hidden;
    max-width: 1440px;
    margin: 0 auto 80px;

    &__grid-img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &__img_1 {
      grid-column-start: 1;
      grid-column-end: 7;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    &__text-wrap {
      margin-left: 50px;
      margin-right: 100px;
      grid-column-start: 7;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    &__img_2 {
      grid-column-start: 1;
      grid-column-end: 8;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &__img_3 {
      grid-column-start: 8;
      grid-column-end: 13;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &__img_4 {
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    &__img_5 {
      grid-column-start: 6;
      grid-column-end: 13;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    &__title {
      font-weight: normal;
      font-size: 36px;
      line-height: 120%;
    }

    &__button {
      width: 176px;
    }
  }
}

@media screen and (max-width: 769px) {
  .welcome {
    margin: 30px 0 60px 16px;

    &__line {
      margin-bottom: 20px;
    }

    &__title {
      margin-bottom: 10px;
    }
  }

  .history {
    margin: 0 auto 60px;

    flex-direction: column;
    align-items: center;

    &__text-wrap {
      margin: 0;
      width: 95vw;
    }

    &__title {
      display: none;

      & + p {
        display: none;
      }

      &_mob {
        display: block;
        width: 95vw;
        margin-bottom: 20px;

        & + p {
          display: block;
          width: 95vw;
          margin-bottom: 20px;
        }
      }
    }

    &__text {
      min-height: 80px;
    }

    &__line {
      display: none;
    }


    &__button {
      width: 95vw;
      margin-top: 20px;
    }
  }

  .can {
    flex-direction: column;
    align-items: center;
    margin: 0 auto 60px;

    &__text-wrap {
      margin: 0;
      width: 95vw;
    }

    &__title {
      margin-bottom: 20px;

      &__br {
        display: none;
      }
    }

    &__text {
      min-height: 80px;
    }


    &__button {
      display: none;

      &_mob {
        display: block;
        width: 95vw;
      }
    }
  }

  .doing {
    grid-template-rows: 147px 300px 300px 300px;
    margin: 0 16px 30px;

    &__grid-img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    &__img_1 {
      grid-column-start: 1;
      grid-column-end: 13;
      grid-row-start: 2;
      grid-row-end: 3;
    }

    &__text-wrap {
      margin: 0;
      grid-column-start: 1;
      grid-column-end: 13;
      grid-row-start: 1;
      grid-row-end: 2;
    }

    &__img_2 {
      grid-column-start: 1;
      grid-column-end: 8;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    &__img_3 {
      grid-column-start: 8;
      grid-column-end: 13;
      grid-row-start: 3;
      grid-row-end: 4;
    }

    &__img_4 {
      grid-column-start: 1;
      grid-column-end: 6;
      grid-row-start: 4;
      grid-row-end: 5;
    }

    &__img_5 {
      grid-column-start: 6;
      grid-column-end: 13;
      grid-row-start: 4;
      grid-row-end: 5;
    }

    &__button {
      display: none;

      &_mob {
        display: block;
        margin: 30px auto 40px;
        width: 95vw;
      }
    }
  }
}

@media screen and (max-width: 481px) {
  .main-search {
    padding: 0;
  }

  .welcome {
    margin: 30px 0 50px 16px;
  }

  .history {
    &__title {
      &_mob {
        margin-bottom: 10px;
      }
    }

    &__text {
      min-height: 108px;
    }
  }

  .can {
    &__text {
      min-height: 108px;
    }
  }
}
</style>
