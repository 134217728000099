<template>
  <div class="bottom-contacts__wrap">
    <section class="bottom-contacts">
      <h3 class="h3 bottom-contacts__title">Связаться с нами</h3>
      <a class="bottom-contacts__text" :href="`mailto:${rentEmail}`">{{ rentEmail }}</a>
      <p class="bottom-contacts__text" @click="copyText('mapCoordinates')">
        Координаты для навигатора: <br>
        <span id="mapCoordinates">{{ mapCoordinates }}</span>
      </p>
      <div class="bottom-contacts__social">
        <a target="_blank" :href="links.telegram"><img :src="require('@/assets/image/icon/telegram.svg')" alt="telegram.svg"></a>
        <a target="_blank" :href="links.instagram"><img :src="require('@/assets/image/icon/instagram.svg')" alt="instagram.svg"></a>
        <a target="_blank" :href="links.vk"><img :src="require('@/assets/image/icon/vk.svg')" alt="vk.svg"></a>
      </div>
    </section>

    <div class="bottom-contacts__map">
      <YaMap></YaMap>
    </div>
  </div>
</template>

<script>
import YaMap from "@/components/blocks/YMap";

export default {
  name: "BottomContacts",
  methods: {
    copyText(id) {
      const r = document.createRange();
      r.selectNode(document.getElementById(id));
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(r);
      document.execCommand('copy');
      window.getSelection().removeAllRanges();

      this.$root.$refs.info.openInfo('', 'Координаты скопированы');
    },
  },
  computed: {
    rentEmail() {
      return this.$store.state.rentEmail;
    },
    mapCoordinates() {
      return this.$store.state.mapCoordinates;
    },
    links() {
      return this.$store.state.links;
    },
  },
  components: {YaMap},
}
</script>

<style scoped lang="scss">
.bottom-contacts {
  height: 443px;
  max-width: 1440px;
  margin: 0 auto;
  padding: 56px 156px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__title {
    margin-bottom: 20px;
    width: 314px;
  }

  &__text {
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #181C16;
    margin-bottom: 16px;
    display: block;
    cursor: pointer;
  }

  &__social {
    margin-top: 39px;

    & a {
      margin-right: 21px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__wrap {
    margin-top: 33px;
    background: #E3F4FF;
    position: relative;
  }

  &__map {
    width: 55%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }
}

@media screen and (max-width: 1281px) {
  .bottom-contacts {
    padding: 56px 0 0 65px;

    &__title {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
    }

    &__social {
      margin-top: 16px;
    }
  }
}

@media screen and (max-width: 1025px) {
  .bottom-contacts {
    padding: 40px 0 0 48px;
    height: 320px;

    &__title {
      font-size: 24px;
      line-height: 28px;
    }

    &__social {
      margin-top: 0;
    }

    &__map {
      width: 50%;
    }
  }
}

@media screen and (max-width: 769px) {
  .bottom-contacts {
    padding-left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &__map {
      width: 100%;
      position: static;
      height: 200px;
    }
  }
}

@media screen and (max-width: 481px) {

}
</style>
