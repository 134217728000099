<template>
  <div class="auth-modal auth-modal_pass">
    <img @click="$emit('closeModal')" class="auth-modal__close" src="@/assets/image/modal-cross.svg" alt="modal-cross.svg">
    <div class="auth-modal__pass-reset">
      <h3>Создайте новый пароль</h3>
      <p>Для большей надёжности используйте комбинацию цифр, специальных символов, заглавных и строчных букв.</p>
    </div>

    <form @submit.prevent="sendForm">
      <div class="auth-modal__inputs">

        <input required minlength="8" class="input" type="password" v-model="password" placeholder="Пароль">
        <p class="input__error" v-for="error in errors.password">{{ error }}</p>

        <input required minlength="8" class="input" type="password" v-model="password_r" placeholder="Повторите пароль">
        <p class="input__error" v-for="error in errors.password_r">{{ error }}</p>

        <p class="input__error" v-for="error in errors.total">{{ error }}</p>
      </div>

      <button :disabled="isLoading" class="button_orange auth-modal__send" type="submit">
        <SvgPreloader v-if="isLoading" type="orange"/>
        <span v-else>Отправить</span>
      </button>
    </form>
  </div>
</template>

<script>
import {httpApi} from "@/helper/httpHelper";
import authTypes from "@/constants/authTypes";
import SvgPreloader from "@/components/blocks/SvgPreloader.vue";
import {setUser} from "@/services/authService";

export default {
  name: "NewPass",
  components: {SvgPreloader},
  data() {
    return {
      isLoading: false,
      password: '',
      password_r: '',
      errors: {},
    }
  },
  methods: {
    setLoginType() {
      this.$emit('changeType', authTypes.login);
    },
    sendForm() {
      this.isLoading = true;
      this.errors = {};
      const {token, email} = this.$route.query;

      httpApi({
        method: 'POST',
        url: 'new-password',
        data: {password: this.password, password_r: this.password_r, token, email}
      })
        .then(res => {
          this.$store.commit('setIsAuth', true);
          const {id, name, email, phone, avatar, token} = res.data;
          setUser(id, name, email, phone, avatar, token);

          this.$emit('closeModal');
          this.$router.push('/');
          this.$root.$refs.info.openInfo(
            'Усешная смена пароля',
            'Вы успешно сменили пароль'
          );
        })
        .catch(err => {
          this.errors = err.response.data.errors;

          if (this.errors.message) {
            this.$emit('closeModal');
            this.$router.push('/');
            this.$root.$refs.info.openInfo(
              '',
              this.errors.message
            );
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
}
</script>

<style scoped lang="scss">
.auth-modal {
  &_pass {
    height: 441px;
  }

  &__pass-reset {
    & h3 {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      color: #181C16;
      margin: 0;
    }

    & p {
      max-width: 450px;;
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #181C16;
      margin: 7px 0 19px;
    }
  }

  &__inputs {
    &_reset-pass {
      padding: 20px 12px 30px;
    }

    &__info {
      font-weight: normal;
      font-size: 14px;
      line-height: 150%;
      color: #181C16;
      margin-bottom: 2px;
    }
  }

  &__back-to-login {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin-top: 40px;
    color: #181C16;
    padding: 6px 8px;
    transition: font-weight 0.3s, background-color 0.3s;
    cursor: pointer;

    & span {
      margin-right: 5px;
    }

    &:hover span, &:focus span {
      font-weight: normal;
    }

    &:hover, &:focus {
      font-weight: bold;
    }

    &:active {
      font-weight: normal;
      background: #EFEFEF;
    }
  }
}

.input_forget-pass {
  width: 302px;
}

@media screen and (max-width: 481px) {
  .auth-modal {
    &_pass {
      height: 401px;
    }

    &__inputs {
      padding: 16px;

      &__info {
        width: 236px;
      }
    }

    &__pass-reset {
      & h3 {
        font-size: 18px;
        line-height: 21px;
      }
    }
  }

  .input_forget-pass {
    width: 236px;
  }
}
</style>
