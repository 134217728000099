<template>
  <div class="auth-modal auth-modal_log">
    <img @click="$emit('closeModal')" class="auth-modal__close" src="@/assets/image/modal-cross.svg"
         alt="modal-cross.svg">
    <h3 class="auth-modal__title">Google авторизация</h3>

    <div class="auth-modal__reg-with">
      <p class="input__error reg-with-error" v-for="error in errors.total">{{ error }}</p>
      <div class="reg-with-icons">
        <button type="button" @click.prevent="redirectToGoogle" class="clear-button" :disabled="isLoading">
          <SvgPreloader v-if="isLoading" type="orange" class="reg-with-icon"/>
          <img v-else :src="require('@/assets/image/icon/google.svg')" alt="google" class="reg-with-icon">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {httpApi} from "@/helper/httpHelper";
import {setUser} from "@/services/authService";
import SendEmailButton from "@/components/blocks/AuthModal/SendEmailButton.vue";
import SvgPreloader from "@/components/blocks/SvgPreloader.vue";

export default {
  name: "Login",
  components: {SvgPreloader, SendEmailButton},
  data() {
    return {
      isLoading: false,
      errors: {},
    }
  },
  mounted() {
    this.sendAuth()
  },
  methods: {
    sendAuth() {
      this.isLoading = true;
      this.errors = {};
      const query = this.$route.query;

      if (query.code) {
        httpApi.post('auth/google/callback', query)
          .then(res => {
            this.$emit('closeModal');
            this.$store.commit('setIsAuth', true);
            this.$router.push('/');
            const {id, name, email, phone, avatar, token} = res.data;
            setUser(id, name, email, phone, avatar, token);
          })
          .catch(err => {
            this.errors = err.response.data.errors;
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
        this.errors = {total: ['Ошибка авторизации. Пожалуйста попробуйте снова.']};
      }
    },
    redirectToGoogle() {
      this.isLoading = true;
      this.errors = {};

      httpApi.get('auth/google/get-redirect-url')
        .then(res => {
          window.location.replace(res.data);
        })
        .catch(err => {
          this.errors = err.response.data.errors;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
}
</script>

<style scoped lang="scss">
.reg-with-icon {
  height: 90px;
}

.reg-with-error {
  font-size: 18px;
  line-height: 120%;
}

.clear-button {
  background: none;
  border: none;
  cursor: pointer;

  &:disabled {
    cursor: auto;
  }
}

.auth-modal {
  &_log {
    height: 577px;
  }
}

.reg-with-icons {
  margin-top: 12px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

@media screen and (max-width: 481px) {
  .auth-modal {
    &_log {
      height: 446px;
    }

    &__inputs_log {
      padding: 16px 16px 33px;
    }
  }

  .reg-with-error {
    font-size: 14px;
    line-height: 100%;
  }
}
</style>
