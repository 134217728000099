<template>
  <div class="scroll-to-top">
    <button @click="scrollToTop" class="scroll-to-top__button"><img src="../../assets/image/arrows/top-white.svg" alt="top-white.svg"></button>
  </div>
</template>

<script>
export default {
  name: "ScrollToBottom",
  data() {
    return {
      intervalId: 0,
    }
  },
  methods: {
    scrollToTop() {
      let currHeight = window.pageYOffset;
      let step = 20;
      this.intervalId = setInterval(() => {
        if (currHeight <= 70) step = 1.5;
        if (currHeight <= 2) {
          currHeight = 0;
          clearInterval(this.intervalId);
          this.intervalId = 0;
        }
        window.scrollTo( 0, currHeight -= step);
      }, 5);
    }
  },
}
</script>

<style scoped lang="scss">
.scroll-to-top {
  display: flex;
  justify-content: center;

  &__button {
    width: 45px;
    height: 45px;
    background: rgba(142, 206, 249, 0.53);
    border: none;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
