<template>
  <transition name="fade">
    <div v-if="isOpen" @click.self="closeAuth" class="auth-modal__wrap">
      <component :is="componentName" @closeModal="closeAuth" @changeType="changeType" />
    </div>
  </transition>
</template>
<script>
import authTypes from "@/constants/authTypes";
import Registration from "@/components/blocks/AuthModal/Registration.vue";
import Login from "@/components/blocks/AuthModal/Login.vue";
import ResetPass from "@/components/blocks/AuthModal/ResetPass.vue";
import NewPass from "@/components/blocks/AuthModal/NewPass.vue";
import Google from "@/components/blocks/AuthModal/Google.vue";

export default {
  name: "AuthModal",
  components: {ResetPass, Login, Registration, NewPass, Google},
  data() {
    return {
      isOpen: false,
      type: authTypes.registration,
      componentMap: {
        [authTypes.registration]: 'Registration',
        [authTypes.login]: 'Login',
        [authTypes.resetPass]: 'ResetPass',
        [authTypes.newPass]: 'NewPass',
        [authTypes.googleAuth]: 'Google',
      }
    }
  },
  computed: {
    routerName() {
      return this.$route.name;
    },
    componentName() {
      return this.componentMap[this.type];
    },
  },
  methods: {
    openAuthModal() {
      if (this.$route.name === 'ResetPass') {
        this.openAuth(authTypes.newPass);
      } else if (this.$route.name === 'Login') {
        this.openAuth(authTypes.login);
      } else if (this.$route.name === 'Registration') {
        this.openAuth(authTypes.registration);
      } else if (this.$route.name === 'GoogleAuth') {
        this.openAuth(authTypes.googleAuth);
      }
    },
    changeType(type) {
      this.type = type;
    },
    openAuth(type = this.type) {
      this.type = type;
      this.isOpen = true;
      document.body.classList.add('no-scroll');
    },
    closeAuth() {
      this.isOpen = false;
      document.body.classList.remove('no-scroll')
    }
  },
  watch: {
    routerName() {
      this.openAuthModal();
    }
  }
}
</script>

<style lang="scss">
.auth-modal {
  overflow: hidden;
  border-radius: 8px;
  width: 640px;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
  margin-top: 10vh;
  margin-bottom: 10vh;

  &__wrap {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(36, 36, 36, 0.43);
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    justify-content: center;
    overflow: scroll;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
  }

  &__title {
    font-weight: normal;
    font-size: 36px;
    line-height: 120%;
    color: #181C16;
    margin: 0 0 16px 0;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    background: #8ECEF944;
    border-radius: 2px;
    padding: 24px;
    margin: 8px 0;
    position: relative;
  }

  &__send {
    width: 326px;
    height: 40px;
    font-weight: normal;
    font-size: 14px !important;
    line-height: 150%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__to-login {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #181C16;
    margin-top: 32px;

    &__button {
      font-weight: 700;
      background: #FFFFFF;
      border: none;
      cursor: pointer;
    }
  }
}

$input-width: 280px;

.input {
  padding: 10px 16px;
  background: #FFFFFF;
  border: 2px solid #8ECEF9;
  box-sizing: border-box;
  border-radius: 2px;
  width: $input-width;
  height: 41px;
  margin-top: 7px;
  transition: color 0.3s;

  &::placeholder {
    transition: color 0.3s;
  }

  &:hover, &:focus {
    outline: none;
    color: #8ECEF9;
  }

  &:hover::placeholder, &:focus::placeholder {
    color: #8ECEF9;
  }

  &__error {
    font-weight: normal;
    font-size: 11px;
    line-height: 13px;
    color: #FA5956;
    margin: 4px 0;
    width: $input-width;
  }

  &:first-child {
    margin: 0;
  }
}

@media screen and (max-width: 769px) {
  .auth-modal {
    width: 400px;
  }
}

@media screen and (max-width: 481px) {
  .auth-modal {
    width: 300px;

    &__close {
      top: 10px;
      right: 10px;
      width: 40px;
      height: 40px;
    }

    &__title {
      font-size: 18px;
      line-height: 21px;
      margin: 0 0 8px 0;
    }

    &__inputs {
      padding: 16px;
    }

    &__send {
      width: 268px;
    }

    &__to-login {
      margin-top: 20px;
    }
  }

  $input-width: 240px;

  .input {
    width: $input-width;

    &__error {
      width: $input-width;
    }
  }
}
</style>
