<template>
    <svg class="svg-loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" width="110px" height="30px">
      <circle cx="84" cy="50" r="10" :fill="color">
        <animate attributeName="r" repeatCount="indefinite" dur="0.5319148936170213s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
        <animate attributeName="ry" repeatCount="indefinite" dur="2.127659574468085s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#e15b64;#abbd81;#f8b26a;#f47e60;#e15b64" begin="0s"></animate>
      </circle>
      <circle cx="16" cy="50" r="10" :fill="color">
        <animate attributeName="r" repeatCount="indefinite" dur="2.127659574468085s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
        <animate attributeName="cx" repeatCount="indefinite" dur="2.127659574468085s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
      </circle>
      <circle cx="50" cy="50" r="10" :fill="color">
        <animate attributeName="r" repeatCount="indefinite" dur="2.127659574468085s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.5319148936170213s"></animate>
        <animate attributeName="cx" repeatCount="indefinite" dur="2.127659574468085s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.5319148936170213s"></animate>
      </circle>
      <circle cx="84" cy="50" r="10" :fill="color">
        <animate attributeName="r" repeatCount="indefinite" dur="2.127659574468085s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.0638297872340425s"></animate>
        <animate attributeName="cx" repeatCount="indefinite" dur="2.127659574468085s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.0638297872340425s"></animate>
      </circle>
      <circle cx="16" cy="50" r="10" :fill="color">
        <animate attributeName="r" repeatCount="indefinite" dur="2.127659574468085s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.5957446808510638s"></animate>
        <animate attributeName="cx" repeatCount="indefinite" dur="2.127659574468085s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-1.5957446808510638s"></animate>
      </circle>
    </svg>
</template>

<script>
export default {
  name: "SvgPreloader",
  props: {
    type: {
      type: String,
      default: 'blue',
    }
  },
  computed: {
    color() {
      return this.type === 'blue' ? this.blueColor : this.orangeColor;
    },
  },
  data() {
    return {
      blueColor: '#8ECEF9',
      orangeColor: '#FF7640',
    };
  },
}
</script>
