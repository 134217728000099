export function setMaskOnPhone(phone, val, originVal) {
  let phoneWithMask = '+7 (___) ___-__-__';
  const numsArray = [];
  const oldNums = phone.match(/\d/g);

  if (oldNums) {
    oldNums.shift();
    numsArray.push(...oldNums);
  }
  if (originVal) numsArray.push(val);

  if (numsArray && numsArray.length) {
    for (const elem of numsArray) phoneWithMask = phoneWithMask.replace('_', elem);
  }
  let lastIndexIn = 0;
  for (const symbolKey in phoneWithMask) {
    const symbol = phoneWithMask[symbolKey];
    if (/\d/.test(symbol)) lastIndexIn = +symbolKey + 1;
  }
  phoneWithMask = phoneWithMask.slice(0, lastIndexIn)

  return phoneWithMask;
}

export function setCursorPosition(elem) {
  if (elem.setSelectionRange) {
    let pos = Number(elem.selectionStart);
    if (pos >= 1 && pos <= 4) pos = 5;
    else if (pos >= 8 && pos <= 9) pos = 10;
    else if (pos === 13) pos = 14;
    else if (pos === 16) pos = 17;

    setTimeout(() => elem.setSelectionRange(pos, pos), 10)
  }
}

export function checkPhone(phone) {
  const regex = /^\+7 \(([\d|_]{3})\) ([\d|_]{3})-([\d|_]{2})-([\d|_]{2})$/;
  return regex.test(phone)
}
