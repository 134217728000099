<template>
  <div v-if="!isLoading" class="house__list" id="house-list">
    <div class="house-cart" v-for="(house, index) in houses" :key="index">
      <div class="house-cart__header">
        <div class="house-cart__text">
          <h3>Дом “{{ house.title }}”</h3>
          <p>{{ house.description }}</p>
        </div>
        <div class="house-cart__text-right">
          <div class="house-cart__price__wrap">
            <transition name="fade-price">
              <div v-show="isWeekend" class="house-cart__price">
                <p>
                  {{ house.weekendsPrice }}₽ / выходные
                </p>
              </div>
            </transition>
            <transition name="fade-price">
              <div v-show="!isWeekend" class="house-cart__price">
                <p>
                  {{ house.weekdaysPrice }}₽ / будни
                </p>
              </div>
            </transition>
          </div>
          <div class="house-cart__button-wrap">
            <button class="house-cart__button button_orange" @click="openCalendar(`calendar-${index}`)">Свободные даты
            </button>
            <Calendar :inputId="`calendar-${index}`" :isOpen="isOpenCalendar[`calendar-${index}`]" mode="range"
                      :disable="house.orders"></Calendar>
          </div>
        </div>
      </div>
      <div class="house-cart__main" :class="dateFrom && dateTo ? 'house-cart__main_more' : ''">
        <Carousel :id="(`home-${index}`)" :slides="house.imgs"></Carousel>
        <div class="house-cart__info">
          <div class="house-cart__info-icon">
            <img :src="require('@/assets/image/houses/icons/area.svg')" alt="area">
            <span>Площадь дома 50 кв.м.</span>
          </div>
          <div class="house-cart__info-icon">
            <img :src="require('@/assets/image/houses/icons/guests.svg')" alt="guests">
            <span>В доме комфортно разместятся 1-4 человека</span>
          </div>
          <div class="house-cart__info-icon">
            <img :src="require('@/assets/image/houses/icons/rooms.svg')" alt="rooms">
            <span>Комнаты: кухня-гостиная, спальня, совмещённый санузел и открытая терраса</span>
          </div>
          <div class="house-cart__info-icon">
            <img :src="require('@/assets/image/houses/icons/chan.png')" alt="rooms">
            <span v-if="house.id === 1">Доступна доп услуга "Банный&nbsp;чан"</span>
            <span v-else>Недоступна доп услуга "Банный&nbsp;чан"&nbsp;❌</span>
          </div>
          <div class="house-cart__info-text">
            <h4>Удобства:</h4>
            <p>Туалетные принадлежности, фен, полотенца, посуда, чайник, варочная панель, микроволновка,
              холодильник</p>
          </div>
        </div>
      </div>
      <div class="house-cart__booking">
        <div class="house-cart__booking-total" v-if="dateFrom !== '' && dateTo !== ''">
          <div v-if="isFreeHome(index) && house.dateList" class="house-cart__booking-total__price-wrap">
            <p class="house-cart__booking-total__price">
              {{ house.dateList.totalPrice }}₽ /
              {{ getDeclination(diffDates, 'сутки', 'суток', 'суток') }}
            </p>
            <div class="date-list" v-if="isFreeHome(index)">
              <img :src="require('@/assets/image/icon/warning.svg')" alt="warning">
              <div class="date-list__modal">
                <table class="date-list__table">
                  <tr v-for="(price, date) in house.dateList.list">
                    <td class="date-list__table__date">{{ date }}:</td>
                    <td class="date-list__table__price">{{ price }}₽</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <p class="house-cart__booking-total__dates"
             :class="`house-cart__booking-total__dates_${isFreeHome(index) ? 'success' : 'blocked'}`">
            {{ dateFrom }} - {{ dateTo }}
          </p>
        </div>
        <div v-else></div>

        <button class="button_orange house-cart__booking__button" :disabled="!isFreeHome(index)"
                @click="goToBooking(house.id)"
                :class="!dateFrom && !dateTo ? 'button_orange house-cart__booking__button_full-width' : ''">
          {{
            isFreeHome(index)
                ? 'Забронировать поездку'
                : dateFrom !== '' && dateTo !== '' ? 'Дом на эти даты занят' : 'Выберите даты посещения'
          }}
        </button>
      </div>
    </div>
  </div>
  <Skillet v-else></Skillet>

  <ScrollToBottom></ScrollToBottom>
  <BottomContacts></BottomContacts>
</template>

<script>
import Calendar from "@/components/blocks/Сalendar";
import Carousel from "@/components/blocks/Carousel";
import Search from "@/components/blocks/Search";
import ScrollToBottom from "@/components/blocks/ScrollToBottom";
import BottomContacts from "@/components/blocks/BottomContacts";
import Skillet from "@/components/blocks/Skillet";
import authTypes from "@/constants/authTypes";
import {httpApi} from "@/helper/httpHelper";
import {createDateList} from "@/services/orderService";
import {diffInDays} from "@/services/dateService";

export default {
  name: 'Houses',
  props: {},
  data() {
    return {
      isLoading: true,
      isOpenCalendar: {},
      isWeekend: true,
      houses: {},
    };
  },
  mounted() {
    this.getDataForHouses();
    setInterval(() => this.isWeekend = !this.isWeekend, 4000);
    for (const houseKey in this.houses) this.isOpenCalendar[`calendar-${houseKey}`] = false;
  },
  methods: {
    getDeclination(val, one, two, three) {
      const valEnd = val % 10;
      let declination = one;
      if (valEnd >= 2 && valEnd <= 4) {
        declination = two;
      } else if (+val >= 5 && +val <= 20 || valEnd === 0 || val >= 22 && valEnd !== 1) {
        declination = three;
      }
      return `${val} ${declination}`;
    },
    getDataForHouses() {
      this.isLoading = true;

      httpApi.get( 'data-houses')
        .then(res => {
          this.houses = res.data;
          this.updateTotalPrice();
        })
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    updateTotalPrice() {
      for (const house of this.houses) {
        house.dateList = createDateList(
          this.$store.state.dateFrom,
          this.$store.state.dateTo,
          this.countGuests,
          house
        )
      }
    },
    getPrettyDate(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString().substring(2);
      return `${day}.${month}.${year}`;
    },
    openCalendar(id) {
      this.isOpenCalendar[id] = false;
      setTimeout(() => {
        this.isOpenCalendar[id] = true;
      }, 100)
    },
    isFreeHome(id) {
      const {dateFrom, dateTo} = this.$store.state;
      let isFree = true;
      if (dateFrom && dateTo) {
        this.houses[id].orders.forEach(item => {
          if (
              dateFrom > item.from && dateFrom < item.to ||
              dateTo > item.from && dateTo < item.to ||
              dateFrom <= item.from && dateTo >= item.to
          ) isFree = false;
        })
      } else {
        isFree = false;
      }
      return isFree;
    },
    goToBooking(id) {
      if (localStorage.userToken && localStorage.userToken.length > 1)
        this.$router.push(`/booking/${id}`);
      else {
        this.$root.$refs.auth.openAuth(authTypes.registration)
      }
    },
  },
  computed: {
    diffDates() {
      return diffInDays(new Date(this.$store.state.dateFrom), new Date(this.$store.state.dateTo));
    },
    dateFrom() {
      if (this.$store.state.dateFrom) return this.getPrettyDate(new Date(this.$store.state.dateFrom));
      return '';
    },
    dateTo() {
      if (this.$store.state.dateTo) return this.getPrettyDate(new Date(this.$store.state.dateTo));
      return ''
    },
    dates() {
      return {
        from: this.$store.state.dateFrom,
        to: this.$store.state.dateTo
      }
    },
    countGuests() {
      return this.$store.state.countGuests;
    }
  },
  watch: {
    dates() {
      for (const key in this.isOpenCalendar) {
        this.isOpenCalendar[key] = false;
      }

      this.updateTotalPrice();
    },
    countGuests() {
      this.updateTotalPrice();
    }
  },
  components: {
    Skillet,
    BottomContacts,
    ScrollToBottom,
    Search,
    Calendar,
    Carousel,
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.house {
  &__search {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 96px;
    background: #E3F4FF;
  }

  &__list {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 60px auto 71px;
    max-width: 1440px;
  }

  &-cart {
    width: 85%;
    max-width: 1137px;
    margin-bottom: 100px;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }

    &__header {
      display: flex;
      align-items: flex-start;
      margin-bottom: 20px;
    }

    &__text {
      width: 464px;
      margin-right: 7vw;
      color: #181C16;
      font-family: 'Tenor Sans', sans-serif;

      & h3 {
        font-weight: normal;
        font-size: 36px;
        line-height: 120%;
        margin-bottom: 8px;
      }

      & p {
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
      }

      &-right {
        flex: auto 1;
        display: flex;
        align-items: flex-start;
      }
    }

    &__price {
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      position: absolute;

      &__wrap {
        flex: 1;
        position: relative;
      }

      &-new {
        display: flex;
        align-items: center;

        & img {
          margin-left: 10px;
        }
      }

      &-old {
        text-decoration: line-through;
      }
    }

    &__button {
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      padding: 7.5px 29px;

      &-wrap {
        position: relative;
      }
    }

    &__main {
      display: flex;
      padding-bottom: 90px;

      & div:first-child {
        margin-right: 7vw;
      }
    }

    &__info {
      &-icon {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        & img {
          margin-right: 16px;
          width: 48px;
          height: 48px;
        }

        & span {
          font-weight: normal;
          font-size: 18px;
          line-height: 150%;
        }
      }

      &-text {
        & h4 {
          font-family: Tenor Sans sans-serif;
          font-weight: normal;
          font-size: 24px;
          line-height: 28px;
          color: #181C16;
          margin-bottom: 8px;
        }

        & p {
          font-weight: normal;
          font-size: 18px;
          line-height: 150%;
          color: #181C16;
        }
      }
    }

    &__booking {
      position: absolute;
      bottom: 0;
      right: 0;
      left: calc(464px + 7vw);
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      &-total {
        &__price {
          font-family: Tenor Sans sans-serif;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          color: #181C16;
          display: flex;
          align-items: center;

          &-wrap {
            display: flex;
          }
        }

        &__dates {
          font-weight: 600;
          font-size: 17px;
          line-height: 120%;
          color: #FFFFFF;
          padding: 2px 6px;
          border-radius: 2px;
          margin-top: 8px;

          &_success {
            color: #FFFFFF;
            background: #FF7640;
          }

          &_blocked {
            color: #929292;
            background: #EFEFEF;
          }
        }
      }

      &__button {
        padding: 17px 30px;

        &_full-width {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 1281px) {
  .house {
    &-cart {
      &__text {
        width: 364px;
        margin-right: 4vw;
      }

      &__button {
        padding: 7.5px 9px;
      }

      &__main {
        & div:first-child {
          margin-right: 4vw;
        }
      }

      &__booking {
        left: calc(364px + 4vw);
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .house {
    &__list {
      padding-top: 20px;
      margin: 20px auto 40px;

    }

    &-cart {
      &__header {
        flex-direction: column;
        align-items: stretch;
      }

      &__text {
        margin-bottom: 20px;

        & h3 {
          font-size: 24px;
          line-height: 28px;
        }

        & p {
          font-size: 18px;
          line-height: 21px;
        }

        &-right {
          height: 55px;
        }
      }

      &__info {
        &-icon {
          margin-bottom: 16px;

          & img {
            margin-right: 9px;
          }

          & span {
            font-size: 14px;
            line-height: 150%;
          }
        }

        &-text {
          & h4 {
            font-size: 18px;
            line-height: 21px;
          }

          & p {
            font-size: 14px;
            line-height: 150%;
          }
        }
      }

      &__price {
        font-size: 18px;
        line-height: 21px;

        &-new {
          & img {
            width: 30px;
            height: 30px;
          }
        }
      }

      &__booking {
        left: 0;

        &__button {
          font-size: 14px;
          line-height: 150%;
          padding: 9.5px 22px;
        }
      }
    }
  }
}

@media screen and (max-width: 769px) {
  .house {
    &__search {
      height: 168px;
      align-items: flex-start;
      padding-top: 24px;
    }

    &-cart {
      width: 95vw;

      &__main {
        flex-direction: column;

        & div:first-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 481px) {
  .house {
    &-cart {
      &__main {
        padding-bottom: 70px;
        transition: padding-bottom 0.3s;

        &_more {
          padding-bottom: 125px;
        }
      }

      &__text {
        width: 100%;

        &-right {
          flex-direction: column;
          height: 106px;
          align-items: stretch;
        }
      }

      &__button {
        width: 100%;

        &-wrap {
          width: 100%;
        }
      }

      &__booking {
        flex-direction: column;
        align-items: flex-start;

        &-total {
          margin-bottom: 10px;
        }

        &__button {
          width: 100%;
        }
      }

    }
  }
}


</style>
